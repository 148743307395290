/* eslint-disable quotes */
import React from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LIGHT } from '../../store/theme/colors';
const customersDataList = [
  'Name (first and last)',
  'Nickname',
  'Nationality',
  'Phone number(s)',
  'Address',
  'Country',
  'City',
  'Postcode',
  "Dependent's name",
  'Gender',
  'Birth date',
  'Medical diagnosis/Condition',
  'Therapies of interest',
];
const therapistsDataList = [
  'Designation',
  'Qualifications with relevant certification details',
  'Expertise in Therapies',
  'Years of Experience',
  'Languages known',
  'Achievements and honors',
  'Working hours and schedule as part of the calendar setup',
];

const claimedBenefits = [
  "Enhances children's developmental progress through tailored therapy sessions.",
  'Provides comprehensive support and detailed session notes to track progress.',
  'Facilitates effective therapy treatments, including speech therapy, occupational therapy, behavioral therapy, play therapy, and music and dance therapy.',
];
const intendedUsers = [
  'Children: Primarily designed for children with special needs.',
  'Parents: For parents looking for convenient, expert care for their children.',
  'Therapists: Professional therapists providing specialized care.',
];
const risksAssociated = [
  'Miscommunication: Possible miscommunication between therapists and parents if not clarified properly.',
  'Inappropriate Use: Risk of using the app without proper supervision.',
];
const requiredDisclaimers = [
  'Professional Advice: Always seek the advice of a doctor or qualified healthcare professional with any questions regarding a medical condition.',
  'Not a Substitute for Professional Care: The app is not a substitute for in-person professional medical or therapeutic advice, diagnosis, or treatment.',
  "Supervision Required: Parents should supervise their children's use of the app and therapy sessions.",
  'Data Privacy: Ensure that all personal and medical data shared through the app is kept secure and private.',
];

const permissionList = [
  'Camera and Bluetooth Access: Required for video conferencing to allow real-time interaction between therapists and children.',
  'Send and Receive SMS/Emails: Essential for sending session reminders, receiving confirmations, sharing detailed session notes and updates, and detecting OTP (One-Time Password) messages for user authentication.',
];
const emergencySituations = [
  'In case of any medical emergency, contact your healthcare provider or emergency services immediately.',
];

const provisions = [
  'Email services to send marketing communications',
  'Mapping services',
  'Customer service or support',
  'Providing cloud computing infrastructure',
];
const PrivacyPolicy = ({ isModal = false, hideModal }) => {
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
      }}
    >
      <Grid container justifyContent={'center'} sx={{ px: isModal ? 0 : 2 }}>
        <Grid
          item
          xl={isModal ? 12 : 6}
          md={isModal ? 12 : 8}
          sx={{
            boxShadow: '0px 4px 30px 0px #0000000D',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <Stack
            sx={{
              py: 4,
              bgcolor: (theme) => theme.palette.primary.light,
              mb: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                width: '100%',
                flexWrap: 'wrap',
                textWrap: 'wrap',
              }}
            >
              Privacy Policy
            </Typography>

            {isModal ? (
              <IconButton
                sx={{
                  border: (theme) =>
                    `1px solid ${theme.palette.text.secondary}`,
                  backgroundColor: 'transparent',
                  marginRight: 2,
                }}
                onClick={() => hideModal?.()}
              >
                <Icon icon={'gridicons:cross'} />
              </IconButton>
            ) : null}
          </Stack>
          <Stack sx={{ px: 4, mb: 2 }}>
            <Stack gap={4} sx={{ textAlign: 'justify' }}>
              <Stack gap={1}>
                <Typography variant="h6"></Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    Coables is a software, built specifically to cater to the
                    registered participants (Therapists and Parents) of the
                    product.
                  </Typography>
                  <Typography variant="body1">
                    This Privacy Policy describes the policies and procedures of
                    Coables on the collection, use, access, correction, and
                    disclosure of your personal information on coables.com (the
                    “Site”) and our Mobile Apps. This privacy policy does not
                    relate to any personal information that Coables collects on
                    behalf of, or under the direction, of its clients.
                  </Typography>
                  <Typography variant="body1">
                    Your personal information will include any information
                    which, either alone or with other data, is reasonably
                    available to us and relates to you (“Personal Information”).
                    This Privacy Policy also covers any of your Personal
                    Information which is provided to us and which is used in
                    connection with the marketing of the services, features or
                    content we offer (the “Services”) to our clients and/or the
                    support that we may give you in connection with the
                    provision of our Services and the Mobile Apps.
                  </Typography>
                  <Typography variant="body1">
                    This Privacy Policy does not apply to any third-party
                    applications or software that can be accessed from the Site,
                    the Services or the Mobile Apps, such as external applicant
                    tracking systems, social media websites or partner websites
                    (“Third Party Services”).
                  </Typography>
                  <Typography variant="body1">
                    Information we collect and how we use it :
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">Personal Information</Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    We generally collect and process the following types of
                    Personal Information:
                  </Typography>
                  <Stack>
                    <Typography variant="h6">
                      For Customers, End Users, or Administrators:
                    </Typography>
                    <List dense>
                      {customersDataList.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Stack>
                    <Typography variant="h6">For Therapists:</Typography>
                    <List dense>
                      {therapistsDataList.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Typography variant="body1">
                    In addition, therapists can share any other professional
                    highlights for customers to view and other information
                    customers may choose to provide to Coables and its
                    employees. Coables is not liable or store any information
                    shared by our customers with the therapists while using the
                    product during a session or interaction.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">Contact Information</Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    When you express an interest in obtaining additional
                    information about the Services, the Site, or Mobile Apps,
                    Coables may ask you to provide your personal contact
                    information, such as your name, email address, and phone
                    number. You agree, consent, and acknowledge that this
                    information is used to communicate with you by responding to
                    your requests, comments, and questions.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">
                  Data Collected as a Service Provider
                </Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    As a service provider, Coables systems only collects
                    information as per the Customer (employer’s) requirement.
                    Our Master Subscription Agreement governs the delivery,
                    access, and use of the Services and Mobile Apps, including
                    the processing of Personal Information and data submitted
                    through Services accounts. The Customer (e.g., your
                    employer) controls their Platform and any associated client
                    data. If you have any questions about specific Platform
                    settings, the processing of Personal Information in the
                    Platform, or its privacy practices, please contact the
                    Customer administrator of the Platform you use.
                  </Typography>
                  <Typography variant="body1">
                    Customer data shall be used by Coables in accordance with
                    the Customer’s instructions, applicable terms in the Master
                    Service Agreement, Customer’s use of Services functionality,
                    and as required by applicable law.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">
                  Sharing of your Information
                </Typography>
                <Stack gap={2}>
                  <Stack gap={2}>
                    <Typography variant="h6">Third Party Services</Typography>
                    <Typography variant="body1">
                      At times, you may be able to access other Third-Party
                      Services/websites through the Site, for example by
                      clicking on links to those Third-Party Services from
                      within the Site. We are not responsible for the privacy
                      policies and/or practices of these Third-Party Services,
                      and you are responsible for reading and understanding
                      those Third-Party Service&apos;s privacy policies.
                    </Typography>
                  </Stack>
                  <Stack gap={2}>
                    <Typography variant="h6">
                      Information Shared with Our Service Providers
                    </Typography>
                    <Typography variant="body1">
                      You agree, consent, and acknowledge that we may share your
                      information with third parties who provide services to us.
                      These third parties are authorized to use your Personal
                      Information only as necessary to provide these services to
                      us. These services may include the provision of:
                    </Typography>
                    <Box sx={{ ml: 2 }}>
                      <List dense>
                        {provisions.map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <Typography sx={{ mr: 2 }}>
                                {index + 1}.
                              </Typography>
                              <Typography>{item}</Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">Data Retention</Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    Any Customer may request information regarding the storage
                    and retention of data (“Audit”) by contacting us. Coables
                    shall make reasonable efforts to respond to the Audit in a
                    reasonable time and subject to applicable law and to the
                    protection of Coables&#39;s trade secrets (Customer’s
                    personnel may be required to execute non-disclosure
                    agreements).
                  </Typography>
                  <Typography variant="body1">
                    Coables will retain data it processes on behalf of its
                    customers only for as long as required to provide the
                    Service to its Customers and as necessary to comply with its
                    legal obligations, resolve disputes, and enforce its
                    agreements. The data in Coables is backed up for system
                    continuity purposes, and each backup file may be stored for
                    30 days.
                  </Typography>
                  <Typography variant="body1">
                    After a termination of services by a customer, an automated
                    process will begin that permanently deletes the data in the
                    next cycle (One cycle per quarter). Once begun, this process
                    cannot be reversed, and data will be permanently deleted. In
                    cases where we delete a single user data, some data will not
                    be deleted and shall be kept in an anonymized manner.
                  </Typography>
                  <Typography variant="body1">
                    You agree, consent, and acknowledge that Coables collects
                    and retains metadata and statistical information concerning
                    the use of the service, which is not subject to the deletion
                    procedures in this policy and may be retained by Coables for
                    no more than required to conduct its business. Some data may
                    be retained also on our third-party service providers
                    servers in accordance with their retention policies. You
                    will not be identifiable from this retained metadata or
                    statistical information.
                  </Typography>
                  <Typography variant="body1">
                    Customer may retain Personal Information and other Data
                    about an end user which the Controller owns and the end user
                    may have no access to. If you have any questions about the
                    right of the Customer to retain and process your Personal
                    Information, you should raise this directly with the
                    Customer. You hereby agree not to assert any claim against
                    Coables in this regard and waive any rights regarding such
                    Data and Personal Information, including the right to view
                    and control such Data and Information.
                  </Typography>
                  <Typography variant="body1">
                    Anonymized aggregated data may be retained by Coables for as
                    long as required to provide its services. Contracts and
                    billing information may be retained as required by Coables
                    but at least 5 years from termination or expiration of the
                    relationship with the applicable Customer or party.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">
                  Where do we store your Data?
                </Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    The Data we collect is hosted on the Amazon Web Services
                    (AWS), Mumbai Region which provides advanced security
                    features and is compliant with ISO 27001 and other privacy
                    and security standards. Coables headquarters is based in
                    India from where we provide customer support services, but
                    no customer data is stored.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">
                  Security and storage of information
                </Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    We take great care in implementing, enforcing, and
                    maintaining the security of the Service and our user&apos;s
                    Personal Information. Coables implements, enforces, and
                    maintains security policies to prevent the unauthorized or
                    accidental access to or destruction, loss, modification,
                    use, or disclosure of personal data and monitor compliance
                    with such policies on an ongoing basis.
                  </Typography>
                  <Typography variant="body1">
                    Coables limits access to personal data to those of its
                    personnel who:
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    <List dense>
                      <ListItem sx={{ alignItems: 'start' }}>
                        <Typography sx={{ mr: 2 }}>1.</Typography>
                        <Typography>
                          Require access to fulfill obligations under this
                          Privacy Policy and agreements executed with Coables.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ alignItems: 'start' }}>
                        <Typography sx={{ mr: 2 }}>2.</Typography>
                        <Typography>
                          Have been appropriately and periodically trained on
                          the requirements applicable to the processing, care,
                          and handling of the Personal Information.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ alignItems: 'start' }}>
                        <Typography sx={{ mr: 2 }}>3.</Typography>
                        <Typography>
                          Are under confidentiality obligations as required
                          under applicable law.
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Typography variant="body1">
                    Coables takes steps to ensure that its staff who have access
                    to personal data are honest, reliable, competent, and
                    periodically trained.
                  </Typography>
                  <Typography variant="body1">
                    Coables shall act in accordance with its policies to
                    promptly notify Customer in the event that any personal data
                    processed by Coables on behalf of Customer is lost, stolen,
                    or there has been any unauthorized access to it subject to
                    applicable law and instructions from any agency or
                    authority. Furthermore, Coables undertakes to cooperate with
                    Customer in investigating and remedying any such security
                    breach. If any security breach involves Personal
                    Information, Coables shall promptly take remedial measures,
                    including without limitation, reasonable measures to restore
                    the security of the Personal Information and limit
                    unauthorized or illegal dissemination of the Personal
                    Information or any part thereof.
                  </Typography>
                  <Typography variant="body1">
                    Coables maintains documentation regarding compliance with
                    the requirements of the law, including without limitation
                    documentation of any known breaches and holds reasonable
                    insurance policies in connection with data security.
                  </Typography>
                  <Typography variant="body1">
                    The Service may, from time to time, contain links to
                    external sites. We are not responsible for the operation,
                    privacy policies, or the content of such sites.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">
                  Changes to the privacy policy
                </Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    The terms of this Privacy Policy will govern the use of the
                    Service and any information collected in connection
                    therewith; however, Coables may amend or update this Privacy
                    Policy from time to time. The most current version of this
                    Privacy Policy will always be posted at:{' '}
                    <Link
                      to={'/privacy-policy'}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        variant=""
                        color={'secondary'}
                        sx={{ fontWeight: '700', cursor: 'pointer' }}
                      >
                        Privacy Policy
                      </Typography>
                    </Link>{' '}
                    Unless otherwise agreed with the Customer, we will endeavor
                    to provide notice of material changes to this policy on the
                    homepage of the website and (if applicable) via an email.
                    Such material changes will take effect seven (7) days after
                    such notice was provided on our website or sent by email.
                    Otherwise, all other changes to this Privacy Policy are
                    effective as of the stated “Last Revised” date, and your
                    continued use of Services will constitute your active
                    acceptance of, and agreement to be bound by, the changes to
                    the Privacy Policy.
                  </Typography>
                  <Typography variant="body1">
                    If you have any questions (or comments) concerning this
                    Privacy Policy, you are welcome to send us an email or
                    otherwise contact us at{' '}
                    <Typography
                      onClick={() => {
                        window.location.href = 'mailto:support@coables.com';
                      }}
                      sx={{
                        // color: LIGHT.background.lightBlue,
                        cursor: 'pointer',
                        fontWeight: '700',
                      }}
                      variant=""
                    >
                      support@coables.com
                    </Typography>
                    , and we will make an effort to reply within a reasonable
                    timeframe, and not over 30 business days.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6">App’s Purpose</Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    Coables is designed to provide online therapy sessions for
                    children with special needs, such as Autism, ADHD, Down
                    syndrome, cerebral palsy, GDD, and learning disabilities.
                    The app connects families with highly qualified therapists,
                    allowing for convenient, personalized care from the comfort
                    of their home.
                  </Typography>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 'bold',
                      }}
                    >
                      Claimed Benefits:
                    </Typography>
                    <List dense>
                      {claimedBenefits.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 'bold',
                      }}
                    >
                      Intended Users:
                    </Typography>
                    <List dense>
                      {intendedUsers.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 'bold',
                      }}
                    >
                      Risks Associated with the Use of the App:
                    </Typography>
                    <List dense>
                      {risksAssociated.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 'bold',
                      }}
                    >
                      Required Disclaimers and Warnings:
                    </Typography>
                    <List dense>
                      {requiredDisclaimers.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 'bold',
                      }}
                    >
                      Emergency Situations:
                    </Typography>
                    <List dense>
                      {emergencySituations.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                </Stack>
                <Stack gap={1}>
                  <Typography variant="h6">
                    Access permission required
                  </Typography>
                  <Stack gap={2}>
                    <List dense>
                      {permissionList.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Icon color="#07404B" icon={'oui:dot'} />
                            </ListItemIcon>
                            <Typography>{item}</Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                  <Typography variant="body1">
                    We prioritize your privacy and ensure all data is handled
                    securely. Thank you for your understanding and support. For
                    questions, contact us at{' '}
                    <a
                      style={{
                        color: LIGHT.primary.darker,
                        textDecoration: 'underline',
                      }}
                      target="_blank"
                      href="mailto:info@coables.com"
                      rel="noreferrer"
                    >
                      info@coables.com
                    </a>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PrivacyPolicy;

PrivacyPolicy.propTypes = {
  onboard: PropTypes.bool,
  isModal: PropTypes.bool,
  hideModal: PropTypes.func,
};
