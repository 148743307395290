import { createApiInstance } from './createApiInstance';

import { queryParamsBuilder } from '../../utils/commonFunctions';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getDesignations: build.query({
      query() {
        return {
          url: '/api/designations',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getPreviousDiagnosis: build.query({
      query() {
        return {
          url: '/api/previous-diagnosis',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getQualifications: build.query({
      query(query) {
        return {
          url: `/api/qualifications${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getTherapies: build.query({
      query() {
        return {
          url: '/api/therapies',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getLanguages: build.query({
      query() {
        return {
          url: '/api/languages',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
});

export const {
  useGetDesignationsQuery,
  useGetLanguagesQuery,
  useGetPreviousDiagnosisQuery,
  useGetQualificationsQuery,
  useGetTherapiesQuery,
} = extendedApi;
