import { createApiInstance } from './createApiInstance';

import { queryParamsBuilder } from '../../utils/commonFunctions';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query({
      query(query) {
        return {
          url: `/api/payments${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getPaymentCharge: build.query({
      query() {
        return {
          url: '/api/payment-charges',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getInvoice: build.query({
      query({ id }) {
        return {
          url: `/api/payments/${id}/download-invoice`,
          method: 'GET',
          responseHandler: (response) => {
            if (response.ok) return response.blob();
            else return response.json();
          },
        };
      },
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetPaymentChargeQuery,
  useLazyGetInvoiceQuery,
} = extendedApi;
