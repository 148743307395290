import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <Stack
      gap={2}
      justifyContent={'center'}
      height={'100%'}
      width={'100%'}
      alignItems={'center'}
    >
      <Typography variant="h5">404 Not Found</Typography>
      <Button variant="contained" onClick={() => navigate('/')}>
        GO HOME
      </Button>
    </Stack>
  );
}

export default NotFound;
