import React from 'react';

import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageLoader from '../../../components/common/PageLoader';
import { adminDefaultSpacing } from '../../../defaults';
import { useGetUserInfoQuery } from '../../../store/apis/user';
import Transactions from '../../parents/transactions/Transactions';

const TherapistTransaction = () => {
  const { id } = useParams();
  const {
    data: therapistData,
    isLoading,
    isFetching,
  } = useGetUserInfoQuery({ id, role: 'therapists' }, { skip: !id });
  return isLoading || isFetching ? (
    <PageLoader />
  ) : (
    <Stack width={'100%'} px={adminDefaultSpacing}>
      <Typography mb={3} variant="h5">
        {`${therapistData?.name}'s Transactions`}
      </Typography>
      <Transactions userType="THERAPISTS" />
    </Stack>
  );
};

export default TherapistTransaction;
