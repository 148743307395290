import React from 'react';

import { Icon } from '@iconify/react';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   borderRadius: '32px',
// };
const ModalContainer = ({ handleCancel, open, children, title, ...other }) => {
  return (
    <Dialog onClose={handleCancel} open={!!open} {...other}>
      <Box height={'100%'} sx={{ overflowY: 'auto' }}>
        <Stack sx={{ px: { xs: 2, sm: 4 }, pb: { xs: 2, sm: 4 } }}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={2}
            justifyContent={title ? 'space-between' : 'flex-end'}
            sx={{
              position: 'sticky',
              zIndex: 1000,
              top: 0,
              bgcolor: 'white',
              py: { xs: 2, sm: 4 },
            }}
          >
            {typeof title === 'string' ? (
              <Typography variant="h5">{title}</Typography>
            ) : (
              title
            )}
            <IconButton
              onClick={handleCancel}
              sx={{
                border: (theme) => `1px solid ${theme.palette.text.disabled}`,
              }}
            >
              <Icon icon={'gridicons:cross'} />
            </IconButton>
          </Stack>
          {children}
        </Stack>
      </Box>
    </Dialog>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.any,
  handleCancel: PropTypes.any,
  open: PropTypes.any,
  title: PropTypes.any,
};

export default ModalContainer;
