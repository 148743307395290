/* eslint-disable quotes */
import React, { useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import TourModal from './TourModal';

import childrenDetailsDemo from '../../assets/images/children-details-parent-demo.png';
import personalDetailsDemo from '../../assets/images/personal-details-parent-demo.png';
import personalDetailsTherapistDemo from '../../assets/images/personal-details-therapist-demo.png';
import professionalDetailsTherapistDemo from '../../assets/images/professional-details-therapist-demo.png';
import termsConditionDemo from '../../assets/images/terms-demo.png';
import termsConditionTherapistDemo from '../../assets/images/terms-therapist-demo.png';
import webTour from '../../assets/svg/web-tour.svg';
import ButtonWithLoading from '../../components/common/ButtonWithLoading';
import config from '../../config';
import useGetUserData from '../../hooks/useGetUserData';
const getVideoUrl = (name) => {
  return `${config.baseUrl}videos/${name}`;
};
const parentTour = [
  {
    title: 'Your Personal Details',
    image: personalDetailsDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Unlock the full potential of your account!{' '}
        </Typography>
        <Typography variant="">
          To access all features and personalize your experience, complete our
          quick onboarding process. First, let&apos;s get you started by
          providing some basic information about yourself.
        </Typography>
      </>
    ),
  },
  {
    title: "Your Children's Details",
    image: childrenDetailsDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Help us personalize your child&apos;s therapy experience!{' '}
        </Typography>
        <Typography variant="">
          By providing some basic information about your child, we can tailor
          recommendations and resources to their specific needs.
        </Typography>
      </>
    ),
  },
  {
    title: 'Accept Terms & Conditions',
    image: termsConditionDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Ready to start your journey!{' '}
        </Typography>
        <Typography variant="">
          Just one last step - please review and accept our Terms & Conditions
          to finalize your onboarding. We&apos;re excited to have you on board!
        </Typography>
      </>
    ),
  },
  {
    title: 'Find Therapist',
    videoUrl: getVideoUrl('find-therapist.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Find the perfect therapist for your child!{' '}
        </Typography>
        <Typography variant="">
          You&apos;re all set to search and filter therapists based on your
          needs. Explore their qualifications, availability, and book sessions
          directly - all within our platform.
        </Typography>
      </>
    ),
  },
  {
    title: 'Book Session(s)',
    videoUrl: getVideoUrl('session-booking.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Ready to begin your child&apos;s therapy journey?{' '}
        </Typography>
        <Typography variant="">
          Select a date and time that works for you, or book multiple sessions
          in advance for lasting progress. Secure your spot by completing the
          quick and secure payment process right after choosing your session(s).
          We make scheduling and payment convenient and hassle-free.
        </Typography>
      </>
    ),
  },
  {
    title: 'Calendar',
    videoUrl: getVideoUrl('calender-parent.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Stay organized and in control!{' '}
        </Typography>
        <Typography variant="">
          Our intuitive calendar view allows you to see your child&apos;s
          upcoming therapy sessions clearly categorized by month, week, and day.
          Easily track appointments, manage your schedule, and cancel or
          reschedule sessions (except within 24 hours of the appointment).
        </Typography>
      </>
    ),
  },
  {
    title: 'Meeting with Therapist',
    videoUrl: getVideoUrl('parent-meeting-demo.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Be present for your child&apos;s first therapy session!{' '}
        </Typography>
        <Typography variant="">
          Our secure platform allows you to join the meeting directly. Test and
          configure your microphone and camera for a smooth experience. You can
          also chat with the therapist during the session to ask questions or
          provide additional information.
        </Typography>
      </>
    ),
  },
  {
    title: 'Feedback',
    videoUrl: getVideoUrl('parent-feedback.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Help us improve your child&apos;s therapy experience!{' '}
        </Typography>
        <Typography variant="">
          Your feedback on your child&apos;s therapist is valuable. We&apos;ll
          send you a reminder email after each session, making it easy for you
          to share your experience and help us personalize therapy for your
          child.
        </Typography>
      </>
    ),
  },
];

const therapistTour = [
  {
    title: 'Your Personal Details',
    image: personalDetailsTherapistDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Unlock the full potential of your account!{' '}
        </Typography>
        <Typography variant="">
          To access all features and personalize your experience, complete our
          quick onboarding process. First, let&apos;s get you started by
          providing some basic information about yourself.
        </Typography>
      </>
    ),
  },
  {
    title: 'Your Professional Details',
    image: professionalDetailsTherapistDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Stand out to potential clients!{' '}
        </Typography>
        <Typography variant="">
          By completing your profile with details like therapies,
          qualifications, and expertise, you&apos;ll help parents easily find
          the perfect match for their child&apos;s needs. This will increase
          your visibility and attract clients seeking therapists who specialize
          in specific areas.
        </Typography>
      </>
    ),
  },
  {
    title: 'Sessions Details',
    videoUrl: getVideoUrl('therapist-slot.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Take control of your therapy experience!{' '}
        </Typography>
        <Typography variant="">
          Set your session price, preferred appointment times, and let us know
          when you&apos;ll be unavailable. We offer flexible scheduling options,
          allowing you to define time ranges and choose specific hours on
          selected dates for a truly customized experience.
        </Typography>
      </>
    ),
  },
  {
    title: 'Accept Terms & Conditions',
    image: termsConditionTherapistDemo,
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Ready to start your journey!{' '}
        </Typography>
        <Typography variant="">
          Just one last step - please review and accept our Terms & Conditions
          to finalize your onboarding. We&apos;re excited to have you on board!
        </Typography>
      </>
    ),
  },
  {
    title: 'Calendar',
    videoUrl: getVideoUrl('therapist-calender.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Stay organized and in control!{' '}
        </Typography>
        <Typography variant="">
          Our intuitive calendar view allows you to see your upcoming therapy
          sessions clearly categorized by month, week, and day. Easily track
          appointments, manage your schedule, and cancel sessions (except within
          24 hours of the appointment).
        </Typography>
      </>
    ),
  },
  {
    title: 'Join Meeting with Parent',
    videoUrl: getVideoUrl('therapist-meeting-demo.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Be prepared for your first session!{' '}
        </Typography>
        <Typography variant="">
          Our secure platform allows you to connect with your client. Easily
          test your microphone and camera to ensure a seamless experience. The
          chat function allows you to collaborate effectively with the
          parent/guardian during the session.
        </Typography>
      </>
    ),
  },
  {
    title: 'Take Session Notes',
    videoUrl: getVideoUrl('session-notes-demo.mp4'),
    caption: (
      <>
        <Typography variant="" sx={{ fontWeight: '700' }}>
          Capture key points and share insights!{' '}
        </Typography>
        <Typography variant="">
          Our platform allows you to take text notes during your sessions.
          Easily save your notes as a PDF file to share with parents/guardians
          after the session. They&apos;ll receive the notes directly via email.
        </Typography>
      </>
    ),
  },
];

const publicTour = [
  {
    title: 'Registration',
    videoUrl: getVideoUrl('signup-demo.mp4'),
    caption: (
      <>
        <Typography variant="">
          Join our community! <b>Sign up</b> with your <b>email ID</b> or use
          your <b>existing Google account</b> for a faster login.
        </Typography>
      </>
    ),
  },
  {
    title: 'Verify Your Email',
    videoUrl: getVideoUrl('email-verification-demo.mp4'),
    caption: (
      <>
        <Typography variant="">
          Your account is almost ready! Head to your <b>email Inbox</b> used for
          Sign up. Open the email from <i>coables.com</i> and
          <b> click the verification link</b> to complete your sign up.{' '}
          <i>
            Check the
            <b> spam/ junk</b> if you do not find the email in the Inbox.
          </i>
        </Typography>
      </>
    ),
  },
  {
    title: 'Sign in To Your Account',
    videoUrl: getVideoUrl('signin-demo.mp4'),
    caption: (
      <>
        <Typography variant="">
          Welcome to the club! Your email ID is verified.{' '}
          <b>Login to your account</b> and begin your <b>on-boarding</b> journey
          to explore all our features!
        </Typography>
      </>
    ),
  },
];

const TakeTourModal = ({ isOpen, setIsOpen, tourFor, setTourFor }) => {
  let { role } = useGetUserData();
  role = tourFor || role || 'public';

  const [isTourOpen, setIsTourOpen] = useState(false);

  const tourData =
    role === 'parents'
      ? parentTour
      : role === 'therapists'
        ? therapistTour
        : publicTour;

  const handleCancel = () => {
    const tourData = JSON.parse(localStorage.getItem('tourData')) || [];
    tourData[role] = false;
    tourData.public = false;
    localStorage.setItem('tourData', JSON.stringify(tourData));
    setIsOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleCancel} open={isOpen[role || 'public']}>
        <Stack sx={{ p: { xs: 2, sm: 4 } }}>
          <Box>
            <img style={{ maxHeight: '350px' }} src={webTour} alt="" />
          </Box>
          <Stack sx={{ textAlign: 'center' }}>
            <Typography variant="h4">Explore Our Website Faster!</Typography>
            <Typography variant="subtitle1">
              In just a few minutes, discover everything our website has to
              offer.
            </Typography>
          </Stack>
          <Stack
            alignItems="center"
            direction={'row'}
            justifyContent={'center'}
            gap={4}
            mt={4}
          >
            <ButtonWithLoading
              type="button"
              buttonTextVariant="body1"
              title={'Skip Tour'}
              customStyle={{
                width: 'auto',
                px: 2,
                borderRadius: 2,
              }}
              startIcon={<Icon color="07404B" icon={'entypo:cross'} />}
              variant="outlined"
              onClick={handleCancel}
            />
            <ButtonWithLoading
              type="button"
              buttonTextVariant="body1"
              title={'Take Tour'}
              customStyle={{
                width: 'auto',
                px: 2,
                borderRadius: 2,
              }}
              startIcon={
                <Icon color="07404B" icon={'fa6-regular:paper-plane'} />
              }
              onClick={() => {
                handleCancel();
                setIsTourOpen(true);
              }}
            />
          </Stack>
        </Stack>
      </Dialog>

      {isTourOpen && (
        <TourModal
          open={isTourOpen}
          tourData={tourData}
          handleCancel={() => {
            handleCancel();
            setIsTourOpen(false);
            setTourFor(null);
          }}
        />
      )}
    </>
  );
};

export default TakeTourModal;
TakeTourModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  tourFor: PropTypes.any,
  setTourFor: PropTypes.func,
};
