import { createApiInstance } from './createApiInstance';

import { queryParamsBuilder } from '../../utils/commonFunctions';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    sendContactUs: build.mutation({
      query({ body }) {
        return {
          url: '/api/contactus',
          method: 'POST',
          body,
        };
      },
    }),
    getContactus: build.query({
      query(query) {
        return {
          url: `/api/contactus${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getContactusDetails: build.query({
      query(id) {
        return {
          url: `/api/contactus/${id}`,
          body: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
});

export const {
  useSendContactUsMutation,
  useGetContactusQuery,
  useGetContactusDetailsQuery,
} = extendedApi;
