import { LIGHT } from './colors';
import { pxToRem, themeTypography } from './typography';

const theme = {
  palette: LIGHT,
  typography: themeTypography,
  status: {
    danger: LIGHT.error.main,
    success: LIGHT.success.main,
    primary: LIGHT.primary.main,
  },

  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: LIGHT.background.lightBlue,
            color: LIGHT.secondary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          boxShadow: '-3px -2px 9px 0px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        viewTransitionContainer: {
          '& > div > div': {
            justifyContent: 'space-between !important',
            paddingLeft: 5,
            paddingRight: 5,
          },
          '& div[role=row]': {
            paddingLeft: 5,
            paddingRight: 5,
            justifyContent: 'space-between !important',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          backgroundColor: 'transparent',
          color: LIGHT.secondary.main,
          top: 0,
        },
        thumb: {
          'height': 20,
          'width': 20,
          'boxShadow': '0 0 2px 0px rgba(0, 0, 0, 0.1)',
          '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
          },
          '&:before': {
            boxShadow:
              '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '32px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          textTransform: 'none',
          borderRadius: '32px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        today: {
          'backgroundColor': LIGHT.primary.main,
          'color': LIGHT.secondary.main,
          '&:hover': {
            backgroundColor: LIGHT.primary.main,
          },
          '&:focus': {
            backgroundColor: LIGHT.primary.main,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#D8DFE2',
          border: '1px solid #DFE3E8',
          // borderRadius: '19px 19px 0px 0px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          'backgroundColor': 'white',
          '&:hover': {
            backgroundColor: '#F2F9FB',
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        // elevation: 1,
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 60px 104px -40px #07404B26',
        },
      },
    },
  },
};
export default theme;
