import { useGetUserInfoQuery } from '../store/apis/user';
import { getUserId } from '../utils/commonFunctions';

const useGetUserData = () => {
  const { id, role } = getUserId();
  const { data, isLoading, isFetching, error } = useGetUserInfoQuery(
    { id, role },
    { skip: !id || !['parents', 'therapists'].includes(role) }
  );

  return !error && { isLoading: isFetching || isLoading, data, role };
};

export default useGetUserData;
