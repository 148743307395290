import React from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <Box sx={{ display: 'flex' }} height={'100%'}>
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
