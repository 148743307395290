import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

function HookSelectField({
  options,
  valueField = 'value',
  labelField = 'label',
  renderLabel,
  ...props
}) {
  const { field, fieldState, formState } = useController(props);
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiInputLabel-outlined': {
          color: (theme) => theme.palette.text.primary,
        },
      }}
      fullWidth
      {...field}
      select
      error={
        (fieldState?.isTouched || formState?.isSubmitted) &&
        Boolean(fieldState?.error)
      }
      helperText={
        (fieldState?.isTouched || formState?.isSubmitted) &&
        fieldState?.error?.message
      }
    >
      {options?.map((option, index) => (
        <MenuItem
          style={{ width: '100%' }}
          value={option?.[valueField]}
          key={option?.[valueField]}
        >
          {renderLabel ? renderLabel(option) : option?.[labelField]}
        </MenuItem>
      ))}
    </TextField>
  );
}

HookSelectField.propTypes = {
  children: PropTypes.any,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  keyProp: PropTypes.any,
  label: PropTypes.any,
  labelField: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.any,
  options: PropTypes.array,
  renderLabel: PropTypes.func,
  renderOptionLabel: PropTypes.func,
  valueField: PropTypes.string,
};
export default HookSelectField;
