import { Icon } from '@iconify/react';
import { Grid, Typography, Divider, Stack, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import coablesLogo from './../../assets/svg/coables-footer-logo.svg';

import AppStore from '../../assets/images/app-store.svg';
import GoogleStore from '../../assets/images/google-store.png';
import config from '../../config';
import { defaultSpacing } from '../../defaults';
import { LIGHT } from '../../store/theme/colors';
const footerIcons = [
  {
    icon: 'entypo-social:linkedin',
    link: 'https://www.linkedin.com/company/the-blessed-ones/',
  },
  {
    icon: 'ri:facebook-fill',
    link: 'https://www.facebook.com/theblessedonesfb?mibextid=ZbWKwL',
  },
  {
    icon: 'bi:youtube',
    link: 'https://youtube.com/@theblessedonesyt?si=cTTulqklQw6XPtXO',
  },
  {
    icon: 'grommet-icons:instagram',
    link: 'https://www.instagram.com/coables_?igsh=MThqYWx2MDMzZDloZQ==',
  },
];
export default function Footer() {
  const navigate = useNavigate();

  return (
    <Grid
      flexGrow={1}
      sx={{
        backgroundColor: 'text.primary',
      }}
    >
      <Grid
        sx={{
          px: defaultSpacing,
          py: 2,
        }}
      >
        <Grid
          container
          sx={{
            flexDirection: { sm: 'row', xs: 'column' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'start' },
          }}
          spacing={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={10} sm={4} md={3} lg={2} sx={{}}>
            <img
              width={'100%'}
              onClick={() => {
                navigate('/');
              }}
              height={'100%'}
              style={{ objectFit: 'contain', cursor: 'pointer' }}
              src={coablesLogo}
              alt="coables-logo"
            />
          </Grid>
          <Grid
            item
            md={9}
            sm={12}
            xs={10}
            sx={{
              display: 'flex',
              marginTop: '1rem',
            }}
          >
            <Grid
              container
              gap={{ xs: 0, sm: 2, lg: 8 }}
              justifyContent={'space-between'}
            >
              <Grid
                item
                xs={12}
                sm={3}
                md={2.8}
                sx={{
                  marginBottom: { lg: 0, xs: 4 },
                }}
              >
                <Typography
                  fontWeight={'fontWeightBold'}
                  sx={{ color: LIGHT.background.lightBlue }}
                  variant="subtitle1"
                >
                  Links
                </Typography>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/terms-conditions');
                  }}
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginTop: 1,
                    mb: 2,
                  }}
                  variant="body2"
                >
                  Terms and Conditions
                </Typography>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/privacy-policy');
                  }}
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginTop: 1,
                    mb: 2,
                  }}
                  variant="body2"
                >
                  Privacy Policy
                </Typography>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/refund-policy');
                  }}
                  sx={{
                    color: LIGHT.background.lightBlue,
                  }}
                  variant="body2"
                >
                  Refund Policy
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.5}
                md={3}
                sx={{
                  marginBottom: { lg: 0, xs: 2 },
                }}
              >
                <Typography
                  fontWeight={'fontWeightBold'}
                  sx={{ color: LIGHT.background.lightBlue }}
                  variant="subtitle1"
                >
                  Contact Us
                </Typography>
                <Stack
                  direction={'row'}
                  alignItems={'start'}
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginTop: 1,
                    mb: 2,
                  }}
                  variant="body2"
                >
                  <Box>
                    <Icon
                      style={{ marginRight: '10px', marginTop: '3px' }}
                      height={'18px'}
                      width={'18px'}
                      icon={'mdi:location'}
                    />
                  </Box>
                  <Typography variant="body2">
                    BlessedOnes Private Limited, Twin Bungalow, 1/4, S.No.168
                    Opp Datta Mandir, Wakad, Pune, Maharashtra 411057
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'start'}
                  onClick={() => {
                    window.location.href = 'mailto:support@coables.com';
                  }}
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginTop: 1,
                    cursor: 'pointer',
                    mb: 2,
                  }}
                  variant="body2"
                >
                  <Box>
                    <Icon
                      style={{ marginRight: '10px', marginTop: '3px' }}
                      height={'18px'}
                      width={'18px'}
                      icon={'ic:baseline-email'}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: 'normal',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                    }}
                  >
                    support@coables.com
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'start'}
                  onClick={() => {
                    window.open(
                      'https://wa.me/919867486668',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginTop: 1,
                    cursor: 'pointer',
                    mb: 2,
                  }}
                  variant="body2"
                >
                  <Box>
                    <Icon
                      style={{ marginRight: '10px', marginTop: '1px' }}
                      height={'18px'}
                      width={'18px'}
                      icon={'mingcute:whatsapp-fill'}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: 'normal',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                    }}
                  >
                    Connect with us
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={'auto'}
                sx={{
                  marginBottom: { lg: 0, xs: 2 },
                }}
              >
                <Typography
                  fontWeight={'fontWeightBold'}
                  sx={{ color: LIGHT.background.lightBlue }}
                  variant="subtitle1"
                >
                  Follow Coables
                </Typography>
                <Box
                  sx={{
                    color: LIGHT.background.lightBlue,
                    marginBottom: 3,
                    marginTop: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  variant="subtitle1"
                >
                  {footerIcons.map((iconConfig) => (
                    <span
                      key={iconConfig.icon}
                      style={{
                        height: 35,
                        width: 35,
                        backgroundColor: 'white',
                        borderRadius: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 12,
                      }}
                    >
                      <Icon
                        onClick={() => {
                          window.open(iconConfig.link);
                        }}
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                          marginLeft: 1,
                        }}
                        fontSize={18}
                        icon={iconConfig.icon}
                      />
                    </span>
                  ))}
                </Box>
                <Typography
                  fontWeight={'fontWeightBold'}
                  sx={{ color: LIGHT.background.lightBlue }}
                  variant="subtitle1"
                >
                  Download the App
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    padding: 0,
                  }}
                >
                  <a
                    href="https://apps.apple.com/in/app/coables/id6544796262"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      height: '35px',
                      marginRight: 6,
                      marginTop: 5,
                    }}
                  >
                    <img
                      src={AppStore}
                      alt="Download on the App Store"
                      style={{ height: '35px' }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.coables&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ height: '35px', marginTop: 5 }}
                  >
                    <img
                      src={GoogleStore}
                      alt="Get it on Google Play"
                      style={{ height: '35px' }}
                    />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ background: LIGHT.background.lightBlue, mt: 2 }} />
        <Grid container justifyContent={'space-between'}>
          <Grid item p={2} gap={0.4} xs={12} lg={8}>
            <Stack direction={'row'} gap={1}>
              <Typography
                sx={{ color: LIGHT.background.lightBlue }}
                variant="body2"
              >
                Version {config.version}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ background: LIGHT.background.lightBlue }}
              />
              <Typography
                sx={{ color: LIGHT.background.lightBlue }}
                variant="body2"
              >
                Copyright &copy; 2024 Coables. All Rights Reserved. Advertiser
                is not licensed by the state.
              </Typography>
            </Stack>
          </Grid>
          <Grid item p={2} gap={0.4} xs={12} lg={4}>
            <Typography
              sx={{
                color: LIGHT.background.lightBlue,
                textAlign: { xs: 'left', lg: 'right' },
              }}
              variant="body2"
            >
              Powered By{' '}
              <a
                style={{
                  color: 'white',
                }}
                href="https://webosmotic.com/?referral=coables"
                target="_blank"
                rel="noreferrer"
              >
                WebOsmotic Pvt. Ltd.
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
