import React from 'react';

import { Navigate } from 'react-router-dom';

import getRouteWrapper from './getRouteWrapper';

import AuthLayout from '../layouts/AuthLayout';
import AboutUs from '../pages/about-us/AboutUs';
import ContactUs from '../pages/contact-us/ContactUs';
import SendFeedback from '../pages/parents/send-feedback/SendFeedback';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import RefundPolicy from '../pages/refund-policy/RefundPolicy';
import VerifyToken from '../pages/sign-up/VerifyToken';
import TermsConditions from '../pages/terms-conditions/TermsConditions';
const Transactions = React.lazy(
  () => import('../pages/parents/transactions/Transactions')
);
const Profile = React.lazy(() => import('../pages/parents/profile/Profile'));
const VideoSdk = React.lazy(() => import('../video-sdk/VideoSdk'));
const Sessions = React.lazy(() => import('../pages/parents/sessions/Sessions'));

const Home = React.lazy(() => import('../pages/parents/home/Home'));
const NotFound = React.lazy(() => import('../pages/not-found/NotFound'));
const ParentOnboarding = React.lazy(
  () => import('../pages/parents/onboarding/Onboarding')
);
const PublicTherapistDetail = React.lazy(
  () => import('../pages/parents/home/PublicTherapistDetail')
);

const Calendar = React.lazy(() => import('../pages/parents/calendar/Calendar'));

const parentsRoutes = [
  {
    path: '/send-feedback',
    element: getRouteWrapper(<SendFeedback />),
  },
  {
    path: '/about-us',
    element: getRouteWrapper(<AboutUs />),
  },
  {
    path: '/contact-us',
    element: getRouteWrapper(<ContactUs />),
  },
  {
    path: '/terms-conditions',
    element: getRouteWrapper(<TermsConditions />),
  },
  {
    path: '/privacy-policy',
    element: getRouteWrapper(<PrivacyPolicy />),
  },
  {
    path: '/refund-policy',
    element: getRouteWrapper(<RefundPolicy />),
  },
  {
    path: '/verify-token',
    element: getRouteWrapper(<VerifyToken />),
  },
  {
    path: '/',
    element: getRouteWrapper(<AuthLayout />, false),
    children: [
      {
        index: true,
        element: getRouteWrapper(<Home />, false),
      },
      {
        path: '/therapist/:id',
        element: getRouteWrapper(<PublicTherapistDetail />, false),
      },
    ],
  },

  {
    path: '/parents',
    element: getRouteWrapper(<AuthLayout />),
    children: [
      {
        index: true,
        element: <Navigate to="/" />,
      },
      {
        path: 'onboarding',
        element: getRouteWrapper(<ParentOnboarding />),
      },
      {
        path: 'calendar',
        element: getRouteWrapper(<Calendar />),
      },
      {
        path: 'profile',
        element: getRouteWrapper(<Profile />),
      },
      {
        path: 'join-meeting',
        element: getRouteWrapper(<VideoSdk />),
      },
      {
        path: 'sessions',
        element: getRouteWrapper(<Sessions />),
      },
      {
        path: 'transactions-history',
        element: getRouteWrapper(<Transactions fromParent={true} />),
      },
      {
        path: '*',
        element: getRouteWrapper(<NotFound />),
      },
    ],
  },
  {
    path: '*',
    element: getRouteWrapper(<NotFound />, false),
  },
];

export default parentsRoutes;
