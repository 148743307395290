const devVersion = '1.0.2';
const stagingVersion = '1.0.7';
const prodVersion = '1.0.3';
let version = '';

if (process.env.REACT_APP_ENV === 'development') {
  version = devVersion;
} else if (process.env.REACT_APP_ENV === 'staging') {
  version = stagingVersion;
} else if (process.env.REACT_APP_ENV === 'production') {
  version = prodVersion;
  console.log = () => {};
} else {
  version = devVersion;
}
const config = {
  version: version,
  baseUrl: process.env.REACT_APP_BASE_URL || '/',
  originUrl: window.location.origin || '/',
};

export default config;
