import React from 'react';

import { Alert, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import ParentInfo from './ParentInfo';

import CustomTabPanel from '../../../../components/common/CustomTabPanel';
import { adminDefaultSpacing } from '../../../../defaults';
import { useGetParentQuery } from '../../../../store/apis/user';
import Transactions from '../../../parents/transactions/Transactions';

const ParentDetail = () => {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();

  const { data: parentData } = useGetParentQuery({ id: id }, { skip: !id });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      'id': `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Stack width={'100%'} px={adminDefaultSpacing}>
      {parentData?.status === 'DELETED' && (
        <Alert
          severity="error"
          color="error"
          sx={{
            backgroundColor: (theme) => theme.palette.error.light,
            mb: 3,
          }}
        >
          <Typography sx={{ color: (theme) => theme.palette.error.dark }}>
            This account has been deleted by the parent.
          </Typography>
        </Alert>
      )}
      <Tabs
        indicatorColor="secondary"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Info" {...a11yProps(0)} />
        <Tab label="Transactions" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ParentInfo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Transactions userType="PARENTS" />
      </CustomTabPanel>
    </Stack>
  );
};

export default ParentDetail;
