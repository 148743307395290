import React, { useEffect, useState } from 'react';

import './App.css';

import { AppRouting } from './AppRouting';
import CustomizedSnackbar from './components/common/CustomizedSnackbar';
import Offline from './components/common/Offline';
import OpenInAppButton from './components/common/OpenInAppButton';
import PageLoader from './components/common/PageLoader';
import ScrollToTop from './components/common/ScrollToTop';
import AuthProvider from './context/AuthProvider';
import useGetUserData from './hooks/useGetUserData';

function App() {
  const { isLoading } = useGetUserData();
  const [isOffline, setOffline] = useState(false);
  useEffect(() => {
    const isOffline = () => setOffline(true);
    const isOnline = () => setOffline(false);

    window.addEventListener('offline', isOffline);
    window.addEventListener('online', isOnline);

    return () => {
      window.removeEventListener('offline', isOffline);
      window.removeEventListener('online', isOnline);
    };
  }, []);

  return (
    <>
      <OpenInAppButton />
      <ScrollToTop />
      {!isOffline ? (
        <AuthProvider>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <AppRouting />
              <CustomizedSnackbar />
            </>
          )}
        </AuthProvider>
      ) : (
        <Offline />
      )}
    </>
  );
}

export default App;
