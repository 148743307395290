/* eslint-disable quotes */
import React from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';

const refundPolicy = [
  {
    title: 'Refund Eligibility:',
    description:
      'We understand that circumstances may arise where a refund is necessary. Refunds will be considered under the following conditions:',
    points: [
      'Unsatisfactory service delivery during the online consultation session.',
      'Technical issues preventing the consultation from taking place.',
      'Cancellation of the session by either party with prior notice.',
    ],
  },
  {
    title: 'Refund Process:',
    points: [
      'Refunds will be processed through Razorpay, our payment gateway provider, within 5-7 business days.',
      'The entire amount paid for the consultation session will be refunded, excluding any platform fees applied.',
      'Refunds will be credited back to the original payment method used for the transaction.',
    ],
  },
  {
    title: 'Refund Request Procedure:',
    points: [
      'Session can be canceled by a parent or therapist only 24 hours before the joining time.',
      'On cancellation of a session by a therapist,admin or a parent due to which the online consultation could not take place, refund request process will be promptly initiated as soon as the session is canceled.',
    ],
  },
  {
    title: 'Refund Exceptions:',
    points: [
      'Refunds will not be provided for no-shows without prior notice.',
      'No refunds will be issued for completed consultation sessions deemed satisfactory by both parties.',
    ],
  },
  {
    title: 'Customer Support:',
    points: [
      'Our customer support team is available to assist you with any refund-related queries or concerns.',
      <Typography key="email-support">
        For prompt assistance, please reach out to{' '}
        <Link
          sx={{
            fontWeight: '800',
            color: (theme) => theme.palette.text.primary,
            textDecoration: 'none',
          }}
          href="mailto:support@coables.com"
        >
          support@coables.com
        </Link>
      </Typography>,
    ],
  },
  {
    title: 'Policy Updates:',
    points: [
      'This refund policy is subject to change without prior notice. Any updates will be communicated on our website.',
      'By engaging in our online consultation services, you agree to abide by the terms outlined in this refund policy. We strive to provide a seamless and satisfactory experience for both therapists and parents seeking support for children.',
    ],
  },
];

const RefundPolicy = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
      }}
    >
      <Grid container justifyContent={'center'} sx={{ px: 2 }}>
        <Grid
          item
          xl={6}
          md={8}
          sx={{
            boxShadow: '0px 4px 30px 0px #0000000D',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          {
            <Stack
              sx={{
                py: 4,
                bgcolor: (theme) => theme.palette.primary.light,
                mb: 2,
              }}
            >
              <Typography variant={'h4'} sx={{ textAlign: 'center', px: 4 }}>
                Refund Policy
              </Typography>
            </Stack>
          }
          <Stack sx={{ px: 4, mb: 2 }}>
            <Stack gap={4} sx={{ textAlign: 'justify' }}>
              {refundPolicy.map((policy, index) => {
                return (
                  <Stack key={index} gap={1}>
                    <Typography variant="h6">
                      {index + 1}. {policy.title}
                    </Typography>
                    <Typography>{policy?.description}</Typography>
                    <Stack gap={2}>
                      <List dense>
                        {policy.points.map((point, index) => {
                          return (
                            <ListItem key={index} sx={{ alignItems: 'start' }}>
                              <ListItemIcon sx={{ minWidth: '30px' }}>
                                <Icon
                                  style={{ marginTop: '3px' }}
                                  color="#07404B"
                                  icon={'oui:dot'}
                                />
                              </ListItemIcon>
                              <Typography>{point}</Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Stack>
                  </Stack>
                );
              })}
              <Typography>
                If you have any questions or require further clarification
                regarding our refund policy, please do not hesitate to contact
                us.
              </Typography>
              <Typography>
                Thank you for choosing our platform for your consultation needs.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RefundPolicy;
