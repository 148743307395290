import { createApiInstance } from './createApiInstance';

import {
  queryParamsBuilder,
  updateCacheData,
} from '../../utils/commonFunctions';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query({
      providesTags: ['getUserInfo'],
      query({ id, role }) {
        return {
          url: `/api/${role}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getTherapist: build.query({
      query({ id }) {
        return {
          url: `/api/therapists/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getParent: build.query({
      providesTags: ['getParent'],
      query({ id }) {
        return {
          url: `/api/parents/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    onboardTherapist: build.mutation({
      query({ body, isUpdate }) {
        return {
          url: '/api/therapists/onboarding',
          method: isUpdate ? 'PATCH' : 'POST',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    onboardParent: build.mutation({
      query({ body, isUpdate }) {
        return {
          url: '/api/parents/onboarding',
          method: isUpdate ? 'PATCH' : 'POST',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    changePassword: build.mutation({
      query({ body }) {
        return {
          url: '/api/users/change-password',
          method: 'PATCH',
          body,
        };
      },
    }),
    updateProfile: build.mutation({
      query({ body, id, role }) {
        return {
          url: `/api/${role}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    addKid: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/parents/kids/${id ? id : ''}`,
          method: id ? 'PATCH' : 'POST',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deleteKid: build.mutation({
      query({ id }) {
        return {
          url: `/api/parents/kids/${id || ''}`,
          method: 'DELETE',
          // params: { id },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft.kids = (draft?.kids || []).filter(
                (kid) => kid?._id !== arg?.id
              );
              draft = data.data;
              return draft;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    getTherapists: build.query({
      providesTags: ['getTherapists'],
      query({ filter, customHeader = {} }) {
        return {
          url: `/api/therapists${queryParamsBuilder(filter)}`,
          method: 'GET',
          headers: customHeader,
        };
      },
      transformResponse: (res, meta) => {
        res.data.randomizedKey = meta.response.headers.get('x-sorting-value');
        return res.data;
      },
    }),
    toggleTherapistStatus: build.mutation({
      invalidatesTags: ['getTherapists'],
      query({ body, id }) {
        return {
          url: `/api/therapists/${id}/states`,
          body,
          method: 'PATCH',
        };
      },
      transformResponse: (res) => res.data,
    }),
    getParents: build.query({
      providesTags: ['getParents'],
      query(query) {
        return {
          url: `/api/parents${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => res.data,
    }),
    verifyTherapist: build.mutation({
      invalidatesTags: ['getTherapists'],
      query({ body, id }) {
        return {
          url: `/api/therapists/onboarding-verification/${id}`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;

              return draft;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    getTherapistStatistics: build.query({
      query(query) {
        return {
          url: `/api/dashboards/therapist-statistics${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => res.data,
    }),

    getTherapistChartStatistics: build.query({
      query(query) {
        return {
          url: `/api/dashboards/revenue-statistics${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => res.data,
    }),
    getTherapistReviews: build.query({
      providesTags: ['getTherapistReviews'],
      query({ id, query }) {
        return {
          url: `/api/therapists/${id}/reviews${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => res.data,
    }),
    verifyParentEmail: build.mutation({
      invalidatesTags: ['getParent'],
      query({ body, id }) {
        return {
          url: `/api/admins/verify-email/${id}`,
          body,
          method: 'POST',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: arg.from === 'parent' ? 'getParents' : 'getTherapists',
              dispatch,
              extendedApi,
            },
            (draft) => {
              const index = draft.data.findIndex((d) => d._id === arg.id);
              draft.data[index].isEmailVerified = true;
              return draft;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => res,
    }),
    resendEmailByAdmin: build.mutation({
      query({ id }) {
        return {
          url: `/api/admins/resend-verification-email/${id}`,
          method: 'POST',
        };
      },
      transformResponse: (res) => res,
    }),
    resendVerificationEmail: build.mutation({
      query(query) {
        return {
          url: `/auth/resend-email-verification${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => res,
    }),
    updateEmail: build.mutation({
      query({ body }) {
        if (body.password) body.password = btoa(body.password);
        return {
          url: '/api/users/email',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              console.log('first', JSON.parse(JSON.stringify(draft)), data);
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    updatePhone: build.mutation({
      query({ body }) {
        return {
          url: '/api/users/phone',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    verifyUserPhoneChangeOtp: build.mutation({
      query(body) {
        return {
          url: '/api/users/verifyOtp',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getUserInfo', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    downloadParentReport: build.query({
      query() {
        return {
          url: '/api/dashboards/report/parents',
          method: 'GET',
          responseHandler: (response) => {
            if (response.ok) return response.blob();
            else return response.json();
          },
        };
      },
    }),
    downloadTherapistReport: build.query({
      query() {
        return {
          url: '/api/dashboards/report/therapists',
          method: 'GET',
          responseHandler: (response) => {
            if (response.ok) return response.blob();
            else return response.json();
          },
        };
      },
    }),
  }),
});

export const {
  useOnboardTherapistMutation,
  useOnboardParentMutation,
  useGetTherapistsQuery,
  useGetParentsQuery,
  useGetUserInfoQuery,
  useAddKidMutation,
  useDeleteKidMutation,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useVerifyTherapistMutation,
  useToggleTherapistStatusMutation,
  useGetTherapistStatisticsQuery,
  useGetTherapistChartStatisticsQuery,
  useGetTherapistQuery,
  useLazyGetTherapistQuery,
  useGetParentQuery,
  useGetTherapistReviewsQuery,
  useResendEmailByAdminMutation,
  useVerifyParentEmailMutation,
  useResendVerificationEmailMutation,
  useUpdateEmailMutation,
  useUpdatePhoneMutation,
  useVerifyUserPhoneChangeOtpMutation,
  useLazyDownloadParentReportQuery,
  useLazyDownloadTherapistReportQuery,
} = extendedApi;
