import React from 'react';

import { Icon } from '@iconify/react';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/svg/coable-logo.svg';

const ForgotPasswordWrapper = ({ iconName, title, description, children }) => {
  const navigate = useNavigate();

  return (
    <Stack
      margin={'auto'}
      height={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Paper
        sx={{
          p: { xs: 2, md: 3 },
          m: 2,
          width: { xs: 'auto', md: '550px' },
        }}
      >
        <Stack gap={6} mb={3} justifyContent={'center'} alignItems={'center'}>
          <img
            style={{ cursor: 'pointer', objectFit: 'contain' }}
            onClick={() => {
              navigate('/');
            }}
            width={'185px'}
            height={'60px'}
            src={logo}
            alt="logo"
          />
          <Stack
            sx={{
              backgroundColor: (theme) => theme.palette.primary.contrastText,
              borderRadius: '86px',
            }}
            p={4}
          >
            <IconButton sx={{ cursor: 'default' }} color="primary">
              <Icon icon={iconName} fontSize={100} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack gap={2} mt={2} mb={3}>
          <Typography align="center" variant="h4">
            {title}
          </Typography>
          <Typography align="center" variant="body1">
            {description}
          </Typography>
        </Stack>
        {children}
      </Paper>
    </Stack>
  );
};

ForgotPasswordWrapper.propTypes = {
  children: PropTypes.any,
  description: PropTypes.any,
  iconName: PropTypes.any,
  title: PropTypes.any,
};

export default ForgotPasswordWrapper;
