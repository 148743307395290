import React, { useEffect } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useRoutes,
  matchRoutes,
  useNavigate,
} from 'react-router-dom';

import Error429 from './components/common/Error429';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import Maintenance from './components/common/Maintenance';
import SideBar from './components/common/Sidebar';
import { useAuth } from './context/AuthProvider';
import { drawerWidth } from './defaults';
import useGetUserData from './hooks/useGetUserData';
import TakeTourModal from './pages/tour-demo/TakeTourModal';
import adminRoutes from './routes/AdminRoutes';
import parentsRoutes from './routes/ParentRoutes';
import publicRoutes from './routes/PublicRoutes';
import therapistsRoutes from './routes/TherapistsRoutes';
const defaultTourObj = { therapists: true, parents: true, public: true };

export const AppRouting = () => {
  const auth = useAuth();
  const location = useLocation();
  const { data: userData, role } = useGetUserData();
  const { errFlag } = useSelector((state) => state.errorPage);
  const [isTourOpen, setIsTourOpen] = React.useState(defaultTourObj);
  const [tourFor, setTourFor] = React.useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const tourData = JSON.parse(localStorage.getItem('tourData')) || {};
    if (!Object.keys(tourData).length) {
      localStorage.setItem('tourData', JSON.stringify(defaultTourObj));
      setIsTourOpen(defaultTourObj);
    } else {
      setIsTourOpen(tourData);
    }
  }, []);
  const router = useRoutes(
    role === 'super_admin' // when admin logs in
      ? adminRoutes
      : auth?.authenticated && role === 'therapists' // when user logs in
        ? therapistsRoutes
        : auth?.authenticated && role === 'parents'
          ? parentsRoutes
          : publicRoutes // when not logged in
  );
  const isAdmin = ['super_admin'].includes(role);
  const someRoutes = [
    { path: '/' },
    { path: '/therapist/:id' },
    { path: '/about-us' },
    { path: '/contact-us' },
    { path: '/terms-conditions' },
    { path: '/privacy-policy' },
    { path: '/refund-policy' },
  ];
  const meetingRoutes = [
    { path: '/parents/join-meeting' },
    { path: '/therapists/join-meeting' },
  ];
  const matches = matchRoutes(someRoutes, location);
  const meetingRoutesMatches = matchRoutes(meetingRoutes, location);
  const showHeader =
    (!meetingRoutesMatches?.length && auth?.authenticated) ||
    (!auth?.authenticated && matches);

  const isOnboardingPending = ['IN_PROGRESS', 'NOT_STARTED'].includes(
    userData?.onboarding?.status
  );
  return (
    <>
      <Stack direction={isAdmin ? 'row' : 'column'}>
        {isAdmin && <SideBar />}
        {showHeader && !isAdmin ? (
          <Header
            setTourFor={setTourFor}
            isTourOpen={isTourOpen}
            setIsTourOpen={setIsTourOpen}
          />
        ) : null}
        {role !== 'super_admin' && (
          <TakeTourModal
            tourFor={tourFor}
            setTourFor={setTourFor}
            isOpen={isTourOpen}
            setIsOpen={setIsTourOpen}
          />
        )}
        <Stack
          // height={'100%'}
          mt={showHeader ? 8 : 0}
          // flexGrow={1}
          // sx={{ overflow: 'auto' }}
          minHeight={showHeader ? 'calc(100vh - 64px)' : '100%'}
          width={{ sm: `calc(100% - ${isAdmin ? drawerWidth : 0}px)` }}
        >
          <Stack
            flexGrow={1}
            // height={'100%'}
            // maxHeight={showHeader && !isAdmin ? 'calc(100vh - 64px)' : '100vh'}
            // overflow={'auto'}
            sx={
              {
                // background: 'yellow',
              }
            }
          >
            <Stack
              height={'100%'}
              // minHeight={showHeader && !isAdmin ? 'calc(100vh - 64px)' : '100vh'}
              my={showHeader ? 6 : 0}
            >
              {router} {errFlag && <Error429 />}
            </Stack>
            <Stack height={'100%'} my={showHeader ? 6 : 0}>
              {process.env.REACT_APP_SITE_UNDER_MAINTENANCE === 'true' &&
              window.location.hostname === 'coables.com' ? (
                <Maintenance />
              ) : null}
            </Stack>
          </Stack>
          {showHeader && !isAdmin ? <Footer /> : null}
          {auth?.authenticated &&
            ['parents', 'therapists'].includes(role) &&
            isOnboardingPending &&
            !['/therapists/onboarding', '/parents/onboarding'].includes(
              location.pathname
            ) && (
              <Stack
                display={'flex'}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                  top: 57,
                  position: 'fixed',
                  left: 0,
                  right: 0,
                  zIndex: 1101,
                }}
              >
                <Box
                  display={'flex'}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    py: 1,
                    px: 2,
                    width: 'auto',
                    background: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 3px 10px 0px #07404B26',
                  }}
                >
                  <Typography>
                    Welcome to Coables! Please complete the onboarding process
                    to proceed further
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate(`/${role}/onboarding`);
                    }}
                    sx={{ color: '#90AE21' }}
                  >
                    Complete Onboarding
                  </Button>
                </Box>
              </Stack>
            )}
        </Stack>
      </Stack>
    </>
  );
};
