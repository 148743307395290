import React, { useMemo, useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import { Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import ModalContainer from '../../../components/common/ModalContainer';
import useGetUserData from '../../../hooks/useGetUserData';
import { useLazyGetInvoiceQuery } from '../../../store/apis/razorpay';
import {
  copyToClipboard,
  getSlotTime,
  paymentStatus,
  showToast,
} from '../../../utils/commonFunctions';

const TransactionDetailModal = ({
  handleCancel,
  open,
  transactionData,
  fromAdmin = false,
  userType = '',
}) => {
  const { role } = useGetUserData();
  const [isCopied, setIsCopied] = useState({});
  const [isViewInvoice, setViewInvoice] = useState(false);
  const [isDownloadInvoice, setDownloadInvoice] = useState(false);
  const [getInvoice] = useLazyGetInvoiceQuery();
  const handleCopy = (text, type) => {
    copyToClipboard(text).then(() => {
      if (type === 'transactionId') {
        setIsCopied((prev) => ({ ...prev, transactionId: true }));
      }
      if (type === 'orderId') {
        setIsCopied((prev) => ({ ...prev, orderId: true }));
      }
    });
  };
  const calculatedGST = useMemo(() => {
    if (!transactionData?.refund_status) {
      return transactionData?.platformTax?.toFixed(2);
    } else if (transactionData?.refund_status === 'full') {
      return 0;
    } else if (transactionData?.refund_status === 'partial') {
      const perSessionGST =
        transactionData?.platformTax / transactionData?.sessionIds?.length;
      const refundedGST = transactionData?.refunds?.length * perSessionGST;
      return (transactionData?.platformTax - refundedGST).toFixed(2);
    }
  }, [transactionData]);

  const handleViewInvoice = async (paymentId) => {
    try {
      const response = await getInvoice({ id: transactionData?._id });
      if (response?.isError) {
        if (paymentId) {
          setDownloadInvoice(false);
        } else {
          setViewInvoice(false);
        }
        showToast({ type: 'error', message: response?.error?.data?.message });
        return;
      }
      const blob = await response?.data;
      const url = window.URL.createObjectURL(blob);
      if (paymentId) {
        const link = document.createElement('a');
        link.href = url;
        const fileName = `invoice-${paymentId.split('pay_')[1]}.pdf`;
        link.setAttribute('download', fileName);
        link.click();
        //clean up
        link.remove();
      } else {
        window.open(url, '_blank');
      }
      //clean up
      window.URL.revokeObjectURL(url);
      if (paymentId) {
        setDownloadInvoice(false);
      } else {
        setViewInvoice(false);
      }
    } catch (e) {
      if (paymentId) {
        setDownloadInvoice(false);
      } else {
        setViewInvoice(false);
      }
      showToast({ type: 'error', message: 'Something went wrong!' });
    }
  };
  const subTotal = Number(
    transactionData?.subtotal ??
      transactionData?.amount -
        (transactionData?.platformFee || 0) -
        (transactionData?.platformTax || 0)
  );
  let commission = transactionData?.commission;
  let platformFee = transactionData?.platformFee;
  let paidByParent = transactionData?.amount;

  const remainingSession =
    transactionData?.sessionIds?.length - transactionData?.refunds?.length;

  if (transactionData?.amount_refunded) {
    commission =
      transactionData?.commission *
      (remainingSession / transactionData?.sessionIds?.length);
    platformFee =
      transactionData?.platformFee *
      (remainingSession / transactionData?.sessionIds?.length);
    paidByParent = transactionData?.amount - transactionData?.amount_refunded;
  }

  let paidToTherapists =
    transactionData?.subtotal *
      (remainingSession / transactionData?.sessionIds?.length) -
    commission;

  const finalEarning =
    paidByParent -
    paidToTherapists -
    transactionData?.fee -
    transactionData?.tax -
    calculatedGST;

  const getAmount = (row) => {
    if (role === 'therapists') {
      return paidToTherapists;
    }
    if (role === 'super_admin') {
      return finalEarning?.toFixed(2);
    }
    return paidByParent;
  };
  return (
    transactionData && (
      <ModalContainer
        maxWidth={'sm'}
        fullWidth={true}
        title={
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Typography variant="h5">{`₹ ${Number(getAmount(transactionData))?.toFixed(2)}`}</Typography>
            {paymentStatus(transactionData)}
          </Stack>
        }
        handleCancel={handleCancel}
        open={open}
      >
        <Divider />
        <Stack gap={2}>
          <Stack gap={3} direction={'row'} my={2}>
            <Stack gap={0.5}>
              <Typography variant="subtitle2">Transaction Date</Typography>
              <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                {dayjs(transactionData?.createdAt).format(
                  'ddd, DD MMM YYYY, hh:mm A'
                )}
              </Typography>
            </Stack>
            <Stack>
              <Divider orientation="vertical" />
            </Stack>
            <Stack gap={0.5}>
              <Typography variant="subtitle2">Payment Method</Typography>
              <Typography
                sx={{ textTransform: 'capitalize' }}
                fontWeight={'fontWeightBold'}
                variant="subtitle2"
              >
                {transactionData?.method === 'netbanking'
                  ? `Net Banking (${transactionData?.bank} bank)`
                  : transactionData?.method === 'upi'
                    ? `UPI (${transactionData?.upi?.vpa})`
                    : `${transactionData?.card?.network} (xx${transactionData?.card?.last4})`}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography fontWeight={'fontWeightBold'} variant="h6">
              Transaction Details
            </Typography>
            <Divider sx={{ mt: 1 }} />
            <Stack mt={2} direction={'row'} gap={3}>
              <Stack width={'100%'} gap={2}>
                <Stack direction={'row'} gap={1}>
                  <Typography flex={0.5} variant="subtitle2">
                    Payment Id
                  </Typography>
                  <Stack flex={1} direction={'row'} gap={1}>
                    <Typography
                      fontWeight={'fontWeightBold'}
                      variant="subtitle2"
                    >
                      {transactionData?.id}
                    </Typography>
                    <Icon
                      onClick={() =>
                        handleCopy(transactionData?.id, 'transactionId')
                      }
                      style={{ cursor: 'pointer' }}
                      fontSize={20}
                      icon={'fluent:copy-16-regular'}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        ml: '-8px',
                        mt: '-4px',
                      }}
                    >
                      {isCopied.transactionId ? 'copied!' : ''}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={'row'} gap={1}>
                  <Typography flex={0.5} variant="subtitle2">
                    Order Id
                  </Typography>
                  <Stack flex={1} direction={'row'} gap={1}>
                    <Typography
                      fontWeight={'fontWeightBold'}
                      variant="subtitle2"
                    >
                      {transactionData?.order_id}
                    </Typography>
                    <Icon
                      onClick={() =>
                        handleCopy(transactionData?.order_id, 'orderId')
                      }
                      style={{ cursor: 'pointer' }}
                      fontSize={20}
                      icon={'fluent:copy-16-regular'}
                    />
                    <Typography
                      variant="caption"
                      sx={{ ml: '-8px', mt: '-4px' }}
                    >
                      {isCopied.orderId ? 'copied!' : ''}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  gap={1}
                  sx={{
                    backgroundColor: '#F8F8F8',
                    padding: 2,
                    borderRadius: 4,
                  }}
                >
                  {['super_admin', 'parents'].includes(role) ? (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {role === 'super_admin'
                          ? 'Parent Payment'
                          : 'Payment and Refund Details'}
                      </Typography>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          {` Session Fee (₹ ${transactionData?.subtotal / transactionData.sessionIds.length} x ${transactionData.sessionIds.length})`}
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹ {subTotal?.toFixed(2)}
                        </Typography>
                      </Stack>
                      <>
                        <Stack direction={'row'} gap={1}>
                          <Typography flex={1} variant="subtitle2">
                            Platform Fees{' '}
                            {transactionData?.platformFeeType !== 'fixed'
                              ? `(${transactionData?.platformFeeAmount}%)`
                              : ''}
                          </Typography>
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            ₹ {platformFee?.toFixed(2)}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} gap={1}>
                          <Typography flex={1} variant="subtitle2">
                            GST (Goods and Services Tax -{' '}
                            {transactionData?.platformTaxType !== 'fixed'
                              ? `${transactionData?.platformTaxAmount}%`
                              : ''}
                            )
                          </Typography>
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            ₹ {transactionData?.platformTax?.toFixed(2)}
                          </Typography>
                        </Stack>
                      </>
                      {transactionData?.amount_refunded ? (
                        <Stack direction={'row'} gap={1}>
                          <Typography flex={1} variant="subtitle2">
                            {` Refunded Amount (₹ ${
                              transactionData?.subtotal /
                              transactionData.sessionIds.length
                            } x ${transactionData.refunds.length})`}
                          </Typography>
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            - ₹ {transactionData?.amount_refunded?.toFixed(2)}
                          </Typography>
                        </Stack>
                      ) : null}
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Total Amount Paid
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹ {paidByParent?.toFixed(2)}
                        </Typography>
                      </Stack>
                      {role === 'super_admin' ? <Divider /> : null}
                    </>
                  ) : null}
                  {['super_admin', 'therapists'].includes(role) ? (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {role === 'super_admin'
                          ? 'Therapist Earnings'
                          : 'Earnings Overview'}
                      </Typography>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          {role === 'therapists'
                            ? ` Session Fee (₹ ${transactionData?.subtotal / transactionData.sessionIds.length} x ${transactionData.sessionIds.length})`
                            : 'Total amount received from parent'}
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹{' '}
                          {(role === 'super_admin'
                            ? paidByParent
                            : subTotal
                          )?.toFixed(2)}
                        </Typography>
                      </Stack>
                      {transactionData.refunds.length &&
                      role === 'therapists' ? (
                        <Stack direction={'row'} gap={1}>
                          <Typography flex={1} variant="subtitle2">
                            {` Refunded Amount (₹ ${
                              transactionData?.subtotal /
                              transactionData.sessionIds.length
                            } x ${transactionData.refunds.length})`}
                          </Typography>
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            - ₹{' '}
                            {(
                              transactionData.refunds.length *
                              (transactionData?.subtotal /
                                transactionData.sessionIds.length)
                            )?.toFixed(2)}
                          </Typography>
                        </Stack>
                      ) : null}
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Platform Service Fee (Coables -{' '}
                          {transactionData?.commissionAmountType !== 'fixed'
                            ? `${transactionData?.commissionAmount}%`
                            : ''}
                          )
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          - ₹{' '}
                          {role === 'therapists'
                            ? `${(commission || 0).toFixed(2)}`
                            : (paidByParent - paidToTherapists)?.toFixed(2)}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Net Earnings
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹ {paidToTherapists?.toFixed(2)}
                        </Typography>
                      </Stack>{' '}
                    </>
                  ) : null}
                  {role === 'super_admin' ? (
                    <>
                      <Divider />
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Coables Earnings
                      </Typography>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Total Amount Received from Parent
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹ {paidByParent?.toFixed(2)}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Payment to Therapist
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          - ₹ {paidToTherapists?.toFixed(2)}
                        </Typography>
                      </Stack>{' '}
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Payment Gateway Charges (Razorpay)
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          - ₹ {transactionData?.fee?.toFixed(2)}
                        </Typography>
                      </Stack>{' '}
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Payment Gateway Tax (Razorpay)
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          - ₹ {transactionData?.tax?.toFixed(2)}
                        </Typography>
                      </Stack>{' '}
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          GST (Goods and Services Tax -{' '}
                          {transactionData?.platformTaxType !== 'fixed'
                            ? `${transactionData?.platformTaxAmount}%`
                            : ''}
                          )
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          - ₹ {calculatedGST}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} gap={1}>
                        <Typography flex={1} variant="subtitle2">
                          Net Earnings
                        </Typography>
                        <Typography
                          fontWeight={'fontWeightBold'}
                          variant="subtitle2"
                        >
                          ₹ {finalEarning?.toFixed(2)}
                        </Typography>
                      </Stack>{' '}
                    </>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography fontWeight={'fontWeightBold'} variant="h6">
              {'Session(s)'}
            </Typography>
            <Divider sx={{ mt: 1 }} />
            <Stack mt={2} gap={2}>
              <Stack direction={'row'} gap={1}>
                <Typography flex={0.5} variant="subtitle2">
                  Session Date
                </Typography>
                <Typography
                  flex={1}
                  fontWeight={'fontWeightBold'}
                  variant="subtitle2"
                >
                  {dayjs(transactionData?.sessionIds?.[0]?.startTime).format(
                    'ddd, DD MMMM YYYY'
                  )}{' '}
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography flex={0.5} variant="subtitle2">
                  Booked Session(s)
                </Typography>
                <Stack flex={1} direction={'row'} gap={1} flexWrap={'wrap'}>
                  {transactionData?.sessionIds?.map((session) => {
                    const start12Hour = dayjs(session?.startTime).format(
                      'h:mm A'
                    );
                    const end12Hour = dayjs(session?.endTime).format('h:mm A');
                    const config = getSlotTime(dayjs(session?.startTime));

                    return (
                      <Stack
                        key={session._id}
                        px={2}
                        py={0.5}
                        borderRadius={'8px'}
                        sx={{
                          borderWidth: 1,
                          width: 'fit-content',
                          borderStyle: 'solid',
                          borderColor: config?.textColor,
                          backgroundColor: config?.bgColor,
                        }}
                        direction={'row'}
                      >
                        <Typography
                          variant="subtitle2"
                          color={config?.textColor}
                        >
                          {start12Hour} - {end12Hour}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
              {(fromAdmin || userType === 'THERAPISTS') && (
                <>
                  <Stack direction={'row'} gap={1}>
                    <Typography flex={0.5} variant="subtitle2">
                      Parent Name
                    </Typography>
                    <Typography
                      flex={1}
                      fontWeight={'fontWeightBold'}
                      variant="subtitle2"
                    >
                      {transactionData?.parentId?.name}
                    </Typography>
                  </Stack>{' '}
                  <Stack direction={'row'} gap={1}>
                    <Typography flex={0.5} variant="subtitle2">
                      Parent Contact Info
                    </Typography>
                    <Stack flex={1}>
                      {transactionData?.parentId?.email && (
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                          <Icon icon={'dashicons:email'} />
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            {transactionData?.parentId?.email}
                          </Typography>
                        </Stack>
                      )}
                      {transactionData?.parentId?.phone && (
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                          <Icon icon={'ri:phone-fill'} />
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            {transactionData?.parentId?.phone}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </>
              )}
              {userType !== 'THERAPISTS' && (
                <>
                  <Stack direction={'row'} gap={1}>
                    <Typography flex={0.5} variant="subtitle2">
                      Therapist Name
                    </Typography>
                    <Typography
                      flex={1}
                      fontWeight={'fontWeightBold'}
                      variant="subtitle2"
                    >
                      {transactionData?.therapistId?.name}
                    </Typography>
                  </Stack>{' '}
                  <Stack direction={'row'} gap={1}>
                    <Typography flex={0.5} variant="subtitle2">
                      Therapist Contact Info
                    </Typography>
                    <Stack flex={1}>
                      {transactionData?.therapistId?.email && (
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                          <Icon icon={'dashicons:email'} />
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            {transactionData?.therapistId?.email}
                          </Typography>
                        </Stack>
                      )}
                      {transactionData?.therapistId?.phone && (
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                          <Icon icon={'ri:phone-fill'} />
                          <Typography
                            fontWeight={'fontWeightBold'}
                            variant="subtitle2"
                          >
                            {transactionData?.therapistId?.phone}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </>
              )}
              {transactionData?.refund_status && (
                <Stack direction={'row'}>
                  <Typography flex={0.5} variant="subtitle2">
                    Cancelled Session(s)
                  </Typography>
                  <Stack flex={1} direction={'row'} gap={1} flexWrap={'wrap'}>
                    {transactionData?.sessionIds
                      ?.filter((session) => session.status === 'CANCELLED')
                      .map((session) => {
                        const start12Hour = dayjs(session?.startTime).format(
                          'h:mm A'
                        );
                        const end12Hour = dayjs(session?.endTime).format(
                          'h:mm A'
                        );
                        const config = getSlotTime(dayjs(session?.startTime));

                        return (
                          <Stack
                            key={session._id}
                            px={2}
                            py={0.5}
                            borderRadius={'8px'}
                            sx={{
                              borderWidth: 1,
                              width: 'fit-content',
                              borderStyle: 'solid',
                              borderColor: config?.textColor,
                              backgroundColor: config?.bgColor,
                            }}
                            direction={'row'}
                          >
                            <Typography
                              variant="subtitle2"
                              color={config?.textColor}
                            >
                              {start12Hour} - {end12Hour}
                            </Typography>
                          </Stack>
                        );
                      })}
                  </Stack>
                </Stack>
              )}
              {role === 'parents' ? (
                <Stack
                  justifyContent={'flex-end'}
                  gap={2}
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <ButtonWithLoading
                    isLoading={isViewInvoice}
                    variant="outlined"
                    onClick={() => {
                      setViewInvoice(true);
                      handleViewInvoice();
                    }}
                    customStyle={{ px: 4, mt: 3 }}
                    fullWidth={false}
                    title={'View Invoice'}
                  />
                  <ButtonWithLoading
                    type={'button'}
                    customStyle={{ px: 4, mt: { xs: 0, sm: 3 } }}
                    onClick={() => {
                      setDownloadInvoice(true);
                      handleViewInvoice(transactionData?.id);
                    }}
                    fullWidth={false}
                    title={'Download'}
                    isLoading={isDownloadInvoice}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </ModalContainer>
    )
  );
};

TransactionDetailModal.propTypes = {
  handleCancel: PropTypes.any,
  open: PropTypes.any,
  transactionData: PropTypes.any,
  fromAdmin: PropTypes.bool,
  userType: PropTypes.string,
};

export default TransactionDetailModal;
