import React from 'react';

import { Button, CircularProgress, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

function ButtonWithLoading({
  isLoading,
  title,
  customStyle,
  onClick,
  disabled,
  color,
  startIcon = undefined,
  variant = 'contained',
  type = 'button',
  fullWidth = true,
  buttonTextVariant = 'buttonText',
  className,
  textColor = 'text.primary',
  endIcon,
}) {
  return (
    <Button
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      startIcon={startIcon}
      color={color}
      loading={(!!isLoading).toString()}
      type={type}
      sx={{
        ...customStyle,
      }}
      onClick={onClick}
      disabled={disabled || isLoading}
      endIcon={endIcon}
    >
      <Typography
        color={disabled || isLoading ? 'text.disabled' : textColor}
        variant={buttonTextVariant}
      >
        {title || 'Save'}
      </Typography>
      {isLoading && (
        <CircularProgress
          size={15}
          sx={{ color: (theme) => theme.palette.text.disabled, ml: 1 }}
        />
      )}
    </Button>
  );
}

export default ButtonWithLoading;

ButtonWithLoading.propTypes = {
  buttonTextVariant: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.any,
  endIcon: PropTypes.any,
  color: PropTypes.string,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};
