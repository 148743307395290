import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import TextEndAdornment from '../../../components/common/TextEndAdornment';
import UploadImage from '../../../components/common/UploadImage';
import { HookAutoComplete } from '../../../components/hook-form-fields/HookAutoComplete';
import { HookTextField } from '../../../components/hook-form-fields/HookTextField';
import useGetUserData from '../../../hooks/useGetUserData';
import { useGetLanguagesQuery } from '../../../store/apis/configApi';
import {
  useOnboardTherapistMutation,
  useUpdateProfileMutation,
  useVerifyTherapistMutation,
} from '../../../store/apis/user';
import { showToast } from '../../../utils/commonFunctions';
import validationSchemas from '../../../utils/validationSchemas';
import RejectionForm from '../../admin/therapist/RejectionForm';

const validationSchema = yup.object({
  businessName: validationSchemas.businessName,
  name: validationSchemas.name,
  // phone: validationSchemas.phone,
  street1: validationSchemas.street1,
  street2: validationSchemas.street2,
  city: validationSchemas.city,
  state: validationSchemas.state,
  postalCode: validationSchemas.postalCode,
  country: validationSchemas.country,
  languages: yup
    .array()
    .required('At least one value is required')
    .min(1, 'At least one value is required'),
});
const PrimaryDetailForm = ({
  currentStep,
  getStatus,
  onCancel,
  setCurrentStep,
  therapistData,
  fromAdmin = false,
  fromProfile = false,
  editMode: defaultEditMode,
}) => {
  const { data: languages, isLoading: isLanguagesLoading } =
    useGetLanguagesQuery();
  const [showRejectForm, setRejectForm] = useState(false);
  const [updateProfile, { isLoading: profileUpdate }] =
    useUpdateProfileMutation();
  const [editMode, setEditMode] = useState(
    (!fromProfile && !fromAdmin) || defaultEditMode?.includes('primary')
  );
  const status = getStatus?.();

  useEffect(() => {
    if (defaultEditMode) {
      setEditMode(defaultEditMode?.includes('primary'));
    }
  }, [defaultEditMode]);

  const [verify, { isLoading: verificationLoading }] =
    useVerifyTherapistMutation();

  const [imageUrl, setImageUrl] = useState();
  const [onboard, { isLoading }] = useOnboardTherapistMutation();
  const { role } = useGetUserData();

  useEffect(() => {
    if (therapistData?.profileImage) {
      setImageUrl({ url: therapistData?.profileImage });
    }
  }, [therapistData?.profileImage]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: therapistData?.name || '',
      businessName: therapistData?.info?.businessName || '',
      street1: therapistData?.info?.street1 || '',
      street2: therapistData?.info?.street2 || '',
      city: therapistData?.info?.city || '',
      state: therapistData?.info?.state || '',
      country: therapistData?.info?.country || '',
      postalCode: therapistData?.info?.postalCode || '',
      // phone: therapistData?.phone || '',
      languages:
        (therapistData?.info?.languages || []).map((value) =>
          (languages || []).find((des) => des._id === value)
        ) || [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset({
      name: therapistData?.name || '',
      businessName: therapistData?.info?.businessName || '',
      street1: therapistData?.info?.street1 || '',
      street2: therapistData?.info?.street2 || '',
      city: therapistData?.info?.city || '',
      state: therapistData?.info?.state || '',
      country: therapistData?.info?.country || '',
      postalCode: therapistData?.info?.postalCode || '',
      // phone: therapistData?.phone || '',
      languages: therapistData?.info?.languages || [],
    });
  }, [
    languages,
    reset,
    therapistData?.info?.businessName,
    therapistData?.info?.city,
    therapistData?.info?.country,
    therapistData?.info?.languages,
    therapistData?.info?.postalCode,
    therapistData?.info?.state,
    therapistData?.info?.street1,
    therapistData?.info?.street2,
    therapistData?.name,
    // therapistData?.phone,
  ]);
  const onSubmit = useCallback(
    async (data) => {
      if (!imageUrl?.url) {
        showToast({
          type: 'error',
          message: 'Profile Image required',
        });
        return;
      }
      let formData = new FormData();
      let res = null;
      if (imageUrl?.file) {
        formData.append('profileImage', imageUrl?.file);
      }
      data.languages = data.languages.map((des) => des._id);

      if (fromProfile) {
        formData.append('name', data?.name);
        // formData.append('phone', data?.phone);
        formData.append('step', 1);

        const info = {
          city: data.city,
          state: data.state,
          street1: data.street1,
          street2: data.street2,
          postalCode: data.postalCode,
          country: data.country,
          businessName: data.businessName,
          languages: data.languages,
        };
        formData.append('info', JSON.stringify(info));
        res = await updateProfile({
          body: formData,
          id: therapistData?._id,
          role,
        });
      } else {
        Object.keys(data).forEach((key) => {
          formData.append(
            key,
            typeof data[key] === 'object'
              ? JSON.stringify(data[key])
              : data[key]
          );
        });
        formData.append('onboardingStep', 1);
        res = await onboard({
          body: formData,
          isUpdate: therapistData?.onboarding?.step > 1,
        });
      }
      if (res?.error) {
        showToast({
          type: 'error',
          message: res.error.data.message,
        });
        return;
      }
      if (fromProfile) {
        if (res?.data?.status === 'SUCCESS') {
          showToast({
            type: 'success',
            message: 'Personal Details updated successfully',
          });
        }
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    },
    [
      fromProfile,
      imageUrl,
      onboard,
      role,
      setCurrentStep,
      therapistData?._id,
      therapistData?.onboarding?.step,
      updateProfile,
    ]
  );

  const approveStatus = async (data) => {
    const res = await verify({
      body: {
        step: 1,

        status: 'APPROVED',
      },
      id: therapistData._id,
    });

    if (res?.error) {
      showToast({
        type: 'error',
        message: res.error.data.message,
      });
      return;
    }
    showToast({
      type: 'success',
      message: res.data.message,
    });
  };

  return (
    <>
      <form
        style={{ height: '100%' }}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <Stack height={'100%'} justifyContent={'space-between'}>
          <Stack height={'100%'}>
            {!fromProfile && !fromAdmin && (
              <Typography mb={3} color={'secondary'} align="left" variant="h4">
                Personal Information
              </Typography>
            )}
            <Stack
              width={'100%'}
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={3}
            >
              <Stack alignItems={'center'}>
                <UploadImage
                  editMode={editMode && !isLoading && !profileUpdate}
                  setImageUrl={setImageUrl}
                  imageUrl={imageUrl}
                />
              </Stack>
              <Stack
                width={'100%'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={3}
              >
                <Stack
                  width={'100%'}
                  direction={{ xs: 'column', sm: 'row' }}
                  gap={3}
                >
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    disabled={isLoading || profileUpdate}
                    required={true}
                    control={control}
                    name="name"
                    label="Name"
                    InputProps={{
                      endAdornment: <TextEndAdornment name="ph:user-bold" />,
                    }}
                  />
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    required={true}
                    control={control}
                    disabled={isLoading || profileUpdate}
                    name="businessName"
                    label="Business Name"
                    InputProps={{
                      endAdornment: (
                        <TextEndAdornment name="tdesign:user-business" />
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  width={'100%'}
                  direction={{ xs: 'column', sm: 'row' }}
                  gap={3}
                >
                  <HookAutoComplete
                    isLoading={isLanguagesLoading}
                    readOnly={!editMode}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    name={'languages'}
                    label="Languages"
                    control={control}
                    options={languages}
                  />
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    control={control}
                    name="country"
                    label="Country"
                    InputProps={{
                      endAdornment: (
                        <TextEndAdornment name="mdi:world-wide-web" />
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  width={'100%'}
                  direction={{ xs: 'column', sm: 'row' }}
                  gap={3}
                >
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    control={control}
                    name="state"
                    label="State"
                    InputProps={{
                      endAdornment: (
                        <TextEndAdornment name="material-symbols:real-estate-agent-outline" />
                      ),
                    }}
                  />
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    control={control}
                    name="city"
                    label="City"
                    InputProps={{
                      endAdornment: <TextEndAdornment name="tdesign:city-10" />,
                    }}
                  />
                </Stack>
                <Stack
                  width={'100%'}
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  gap={3}
                >
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    disabled={isLoading || profileUpdate}
                    required={true}
                    control={control}
                    name="street1"
                    label="Street 1"
                    InputProps={{
                      endAdornment: <TextEndAdornment name="mi:location" />,
                    }}
                  />
                  <HookTextField
                    inputProps={{ readOnly: !editMode }}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    control={control}
                    name="street2"
                    label="Street 2"
                    InputProps={{
                      endAdornment: <TextEndAdornment name="mi:location" />,
                    }}
                  />
                </Stack>

                {fromAdmin && (
                  <Stack
                    width={'100%'}
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                    gap={3}
                  >
                    <HookTextField
                      inputProps={{ readOnly: !editMode }}
                      required={true}
                      defaultValue={therapistData?.email || 'N/A'}
                      type={'text'}
                      disabled={isLoading || profileUpdate}
                      control={control}
                      name="email"
                      label="Email"
                      InputProps={{
                        endAdornment: (
                          <TextEndAdornment name="ic:outline-email" />
                        ),
                      }}
                    />
                    <HookTextField
                      inputProps={{ readOnly: !editMode }}
                      required={true}
                      defaultValue={therapistData?.phone || 'N/A'}
                      type={'text'}
                      disabled={isLoading || profileUpdate}
                      control={control}
                      name="phone"
                      label="Phone Number"
                      InputProps={{
                        endAdornment: (
                          <TextEndAdornment name="fluent:call-12-regular" />
                        ),
                      }}
                    />
                  </Stack>
                )}

                <Grid spacing={3} container>
                  <Grid item xs={12} sm={6}>
                    <HookTextField
                      inputProps={{ readOnly: !editMode }}
                      disabled={isLoading || profileUpdate}
                      required={true}
                      control={control}
                      type={'number'}
                      name="postalCode"
                      InputProps={{
                        endAdornment: (
                          <TextEndAdornment name="mdi:file-location-outline" />
                        ),
                      }}
                      label="Zip Code"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Stack>

          <Stack mt={3} justifyContent={'flex-end'} alignItems={'flex-end'}>
            {fromAdmin &&
            status !== 'APPROVED' &&
            !['NOT_STARTED', 'IN_PROGRESS'].includes(
              therapistData?.onboarding?.status
            ) &&
            therapistData?.status !== 'DELETED' ? (
              !showRejectForm ? (
                <Stack flexDirection={'row'} gap={2}>
                  <ButtonWithLoading
                    textColor={'error.main'}
                    variant={'text'}
                    customStyle={{ px: 4, mt: 3 }}
                    fullWidth={false}
                    isLoading={isLoading || profileUpdate}
                    title={'Reject'}
                    onClick={() => {
                      setRejectForm(true);
                    }}
                  />
                  <ButtonWithLoading
                    onClick={approveStatus}
                    type={'button'}
                    customStyle={{ px: 4, mt: 3 }}
                    fullWidth={false}
                    isLoading={
                      isLoading || profileUpdate || verificationLoading
                    }
                    title={'Approve'}
                  />
                </Stack>
              ) : null
            ) : (
              editMode && (
                <Stack flexDirection={'row'} gap={2}>
                  {fromProfile && (
                    <ButtonWithLoading
                      variant="text"
                      onClick={() => {
                        reset({
                          name: therapistData?.name || '',
                          businessName: therapistData?.info?.businessName || '',
                          street1: therapistData?.info?.street1 || '',
                          street2: therapistData?.info?.street2 || '',
                          city: therapistData?.info?.city || '',
                          state: therapistData?.info?.state || '',
                          country: therapistData?.info?.country || '',
                          postalCode: therapistData?.info?.postalCode || '',
                          // phone: therapistData?.phone || '',
                          languages: therapistData?.info?.languages || [],
                        });
                        setImageUrl({ url: therapistData?.profileImage });
                        onCancel();
                        // setEditMode(false);
                      }}
                      customStyle={{ px: 4, mt: 3 }}
                      fullWidth={false}
                      disabled={isLoading || profileUpdate}
                      title={'Cancel'}
                    />
                  )}
                  <ButtonWithLoading
                    type={'submit'}
                    customStyle={{ px: 4, mt: 3 }}
                    fullWidth={false}
                    isLoading={isLoading || profileUpdate}
                    title={fromProfile ? 'Save' : 'Next'}
                  />
                </Stack>
              )
            )}
          </Stack>
        </Stack>
      </form>
      {showRejectForm && (
        <RejectionForm
          step={1}
          therapistId={therapistData._id}
          setRejectForm={setRejectForm}
        />
      )}
    </>
  );
};

PrimaryDetailForm.propTypes = {
  currentStep: PropTypes.any,
  getStatus: PropTypes.func,
  editMode: PropTypes.any,
  fromAdmin: PropTypes.bool,
  fromProfile: PropTypes.bool,
  onCancel: PropTypes.func,
  setCurrentStep: PropTypes.func,
  therapistData: PropTypes.shape({
    _id: PropTypes.any,
    info: PropTypes.shape({
      businessName: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      languages: PropTypes.array,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      street1: PropTypes.string,
      street2: PropTypes.string,
    }),
    name: PropTypes.string,
    onboarding: PropTypes.shape({
      status: PropTypes.string,
      step: PropTypes.number,
    }),
    email: PropTypes.string,
    phone: PropTypes.string,
    profileImage: PropTypes.any,
    status: PropTypes.any,
  }),
};

export default PrimaryDetailForm;
