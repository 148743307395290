export const designations = [
  'Pediatrician',
  'Child Psychiatrist',
  'Developmental Pediatrician',
  'Neurologist',
  'Psychologist',
  'Speech-Language Pathologist',
  'Occupational Therapist',
  'Physical Therapist',
  'Behavioral Therapist',
  'Special Education Teacher',
  'Applied Behavior Analyst (ABA Therapist)',
  'Early Intervention Specialist',
  'Clinical Social Worker',
  'Music Therapist',
  'Art Therapist',
  'Play Therapist',
  'Registered Behavior Technician (RBT)',
  'Board Certified Behavior Analyst (BCBA)',
  'Sensory Integration Therapist',
  'Adaptive Physical Education Specialist',
];

export const previousD = [
  'Autism Spectrum Disorder (ASD)',
  'Attention-Deficit/Hyperactivity Disorder (ADHD)',
  'Down Syndrome',
  'Cerebral Palsy',
  'Intellectual Disability (formerly known as Mental Retardation)',
  'Specific Learning Disorder (e.g., Dyslexia, Dyscalculia)',
  'Speech and Language Disorders (e.g., Expressive Language Disorder, Speech Sound Disorder)',
  'Sensory Processing Disorder',
  'GDD',
  'Epilepsy',
  'Tourette Syndrome',
  'Fragile X Syndrome',
  'Williams Syndrome',
  'Prader-Willi Syndrome',
  'Rett Syndrome',
  'Angelman Syndrome',
  'Muscular Dystrophy',
  'Spina Bifida',
  'Hydrocephalus',
  'Congenital Heart Defects',
];

export const defaultSpacing = { xs: 2, md: 8, lg: 15, xl: 20 };
export const defaultSpacingForVideoSdk = { xs: 2, md: 4, lg: 6, xl: 6 };

export const defaultAgeGroups = ['1-6', '6-10', '10-14'];
export const drawerWidth = 260;
export const adminDefaultSpacing = { xs: 2, md: 4, lg: 4, xl: 4 };

export const therapistListFilters = [
  { value: 'Sponsored', label: 'Sponsored' },
  { value: 'charge:asc', label: 'Price - Low to High' },
  { value: 'charge:desc', label: 'Price - High to Low' },
  { value: 'rating:asc', label: 'Rating - Low to High' },
  { value: 'rating:desc', label: 'Rating - High to Low' },
];

export const statsFilter = [
  { value: 'current_month', label: 'Current Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_6_month', label: 'Last 6 Month' },
  { value: 'last_12_month', label: 'Last 12 month' },
];
export const sessionListFilter = [
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'NOT_ATTENDED', label: 'Not Attended' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
];

export const adminTherapistsListFilter = [
  { value: 'ALL', label: 'All' },
  { value: 'TERMINATED', label: 'Terminated' },
  { value: 'DELETED', label: 'Deleted' },
  { value: 'BLOCKED', label: 'Blocked' },
  { value: 'UNDER_REVERIFICATION', label: 'Reverification Required' },
  { value: 'REVERIFICATION_FAILED', label: 'Reverification Rejected' },
  {
    value: 'PENDING',
    label: 'Onboarding Pending',
  },
  { value: 'ADMIN_STATUS_REJECTED', label: 'Rejected' },
  { value: 'ADMIN_STATUS_APPROVED', label: 'Verified' },
  {
    value: 'ADMIN_STATUS_PENDING',
    label: 'Verification Pending',
  },
];

export const adminParentListFilter = [
  { value: 'ALL', label: 'All' },
  { value: 'NOT_STARTED', label: 'Onboarding Pending' },
  { value: 'IN_PROGRESS', label: 'Onboarding In Progress' },
  { value: 'COMPLETED', label: 'Onboarding Completed' },
  { value: 'DELETED', label: 'Deleted' },
];
