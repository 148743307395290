import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Box,
  CircularProgress,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import InquiryInfoModal from './InquiryInfoModal';

import TableWrapper from '../../../components/common/TableWrapper';
import { adminDefaultSpacing } from '../../../defaults';
import { useGetContactusQuery } from '../../../store/apis/contactus';
import { debounce } from '../../../utils/commonFunctions';

const Inquiries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [openInquiryInfoModal, setOpenInquiryInfoModal] = useState({
    open: false,
    data: null,
  });
  const setFilteredValue = useCallback((searchQuery) => {
    setFilterSearchValue(searchQuery);
  }, []);

  const filter = useMemo(() => {
    return {
      page: Number(searchParams.get('page') || 1),
      limit: searchParams.get('limit') || 10,
    };
  }, [searchParams]);

  useEffect(() => {
    debounce(() => {
      setFilteredValue(searchQuery);
      if (filter.page > 1 && searchQuery) setSearchParams({ page: 1 });
    }, 1000);
  }, [searchQuery, setFilteredValue]);

  const {
    data: inquiries,
    isFetching,
    isLoading,
  } = useGetContactusQuery({
    search: filterSearchValue,
    page: filter.page,
    limit: filter.limit,
  });
  const handleChangePage = (event, newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams((prev) => {
      prev.set('page', newPage + 1);
      return prev;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams({
      limit: parseInt(event.target.value, 10),
      page: 1,
    });
  };
  const columns = [
    {
      id: 'name',
      label: 'Name',
      format: (row) => row?.name,
    },
    {
      id: 'email',
      label: 'Email',
      format: (row) => row?.email,
    },
    {
      id: 'message',
      label: 'Message',
      format: (row) => (
        <>
          <Tooltip title={row?.message} placement="bottom-start">
            <Typography
              sx={{
                width: '10rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="subtitle2"
            >
              {row?.message}
            </Typography>
          </Tooltip>
        </>
      ),
    },
    {
      id: 'date',
      label: 'Date',
      format: (row) => (
        <Typography variant="subtitle2">
          {dayjs(row?.createdAt).format('ddd, DD MMM YYYY, hh:mm A')}
        </Typography>
      ),
    },
  ];
  return (
    <Stack width={'100%'} px={adminDefaultSpacing}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography mb={3} variant="h5">
          Inquiries
        </Typography>
        <TextField
          name="search"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <Icon
                style={{ marginRight: '8px' }}
                fontSize={24}
                icon={'carbon:search'}
              />
            ),
            sx: {
              'backgroundColor': (theme) => theme.palette.background.lightBlue,
              'borderRadius': '32px',
              'mb': '12px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
            },
          }}
          placeholder="Search"
        />
      </Stack>
      {isLoading || isFetching ? (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : inquiries?.data?.length ? (
        <Stack>
          <TableWrapper
            isCollapsible={false}
            rowKey={'_id'}
            rows={inquiries?.data || []}
            columns={columns}
            onRowClick={(row) => {
              setOpenInquiryInfoModal({ open: true, data: row });
            }}
          />
          <TablePagination
            component="div"
            count={inquiries?.metadata?.total}
            page={filter.page - 1}
            rowsPerPage={filter.limit}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      ) : (
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          width={'100%'}
          py={10}
        >
          <Typography>No data found!</Typography>
        </Box>
      )}
      <InquiryInfoModal
        open={openInquiryInfoModal.open}
        inquiryData={openInquiryInfoModal.data}
        handleCancel={() =>
          setOpenInquiryInfoModal((prev) => {
            return { ...prev, open: false };
          })
        }
      />
    </Stack>
  );
};

export default Inquiries;
