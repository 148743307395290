import React from 'react';

import { Icon } from '@iconify/react';
import { IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TextEndAdornment = ({ name, editMode = false, showCount }) => {
  return (
    <IconButton
      tabIndex={-1}
      disableTouchRipple
      disableFocusRipple
      disableRipple
      sx={{
        color: (theme) => theme.palette.text[editMode ? 'disabled' : 'primary'],
        cursor: 'default',
        position: `${showCount ? 'absolute' : 'static'}`,
        bottom: 0,
        right: 0,
      }}
    >
      {showCount ? (
        <Typography variant="caption">{showCount}</Typography>
      ) : (
        <Icon style={{ marginRight: '8px' }} fontSize={24} icon={name} />
      )}
    </IconButton>
  );
};

TextEndAdornment.propTypes = {
  editMode: PropTypes.bool,
  name: PropTypes.any,
  showCount: PropTypes.string,
};

export default TextEndAdornment;
