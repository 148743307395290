import React, { useEffect, useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import ButtonWithLoading from '../../components/common/ButtonWithLoading';
import ModalContainer from '../../components/common/ModalContainer';

const TourModal = ({ open, handleCancel, tourData }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const changeStep = (event) => {
      if (event.key === 'ArrowRight') {
        setStep((prev) => (prev < tourData.length - 1 ? prev + 1 : prev));
      } else if (event.key === 'ArrowLeft') {
        setStep((prev) => (prev > 0 ? prev - 1 : prev));
      }
    };
    window.addEventListener('keydown', changeStep);

    return () => {
      window.removeEventListener('keydown', changeStep);
    };
  }, [open, tourData]);

  return (
    <>
      <ModalContainer
        open={open}
        title={tourData[step]?.title}
        handleCancel={handleCancel}
        // fullWidth
        maxWidth={'xl'}
      >
        <Box>
          <Divider />
          <Stack sx={{ maxHeight: '100vh', maxWidth: '100vw', my: 2 }}>
            {tourData[step].videoUrl ? (
              <video
                controls={false}
                loop={true}
                playsInline
                style={{ objectFit: 'contain', maxHeight: '55vh' }}
                autoPlay={true}
                src={tourData[step]?.videoUrl}
              />
            ) : (
              <img
                style={{ objectFit: 'contain', maxHeight: '55vh' }}
                src={tourData[step]?.image}
                alt={tourData[step]?.title}
              />
            )}
          </Stack>
          <Divider />
          <Box
            sx={{
              textAlign: 'center',
              my: 2,
              maxWidth: { sm: '100%', lg: '50vw' },
              mx: 'auto',
            }}
          >
            {tourData[step]?.caption}
          </Box>
          <Stack
            alignItems="center"
            direction={'row'}
            justifyContent={'center'}
            gap={4}
            mt={2}
          >
            {step > 0 && (
              <ButtonWithLoading
                type="button"
                buttonTextVariant="body1"
                title={'Back'}
                customStyle={{
                  width: 'auto',
                  px: 2,
                  borderRadius: 2,
                }}
                startIcon={<Icon color="07404B" icon={'uil:arrow-left'} />}
                variant="outlined"
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              />
            )}
            {step < tourData.length - 1 ? (
              <ButtonWithLoading
                type="button"
                buttonTextVariant="body1"
                title={'Next'}
                customStyle={{
                  width: 'auto',
                  px: 2,
                  borderRadius: 2,
                }}
                endIcon={<Icon color="07404B" icon={'uil:arrow-right'} />}
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              />
            ) : (
              <ButtonWithLoading
                type="button"
                buttonTextVariant="body1"
                title={'Finish'}
                customStyle={{
                  width: 'auto',
                  px: 2,
                  borderRadius: 2,
                }}
                endIcon={<Icon color="07404B" icon={'uim:check'} />}
                onClick={() => {
                  handleCancel();
                }}
              />
            )}
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export default TourModal;
TourModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  tourData: PropTypes.any,
};
