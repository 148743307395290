import * as React from 'react';

import { Icon } from '@iconify/react';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import coablesLogo from '../../assets/svg/coable-logo.svg';
import { useAuth } from '../../context/AuthProvider';
import { drawerWidth } from '../../defaults';

function SideBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.text.primary,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'block',
          cursor: 'pointer',
          px: 6,
          py: '12px',
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <img
          width={'185px'}
          height={'60px'}
          style={{ objectFit: 'contain' }}
          src={coablesLogo}
          alt="coables-logo"
        />
      </Box>
      {/* <Toolbar /> */}
      <Divider />
      <List sx={{ p: 0 }}>
        {[
          {
            text: 'Dashboard',
            path: '/admin/dashboard',
            icon: 'lucide:layout-dashboard',
          },
          {
            text: 'Therapists',
            path: '/admin/therapists',
            icon: 'material-symbols:physical-therapy-outline',
          },
          {
            text: 'Parents',
            path: '/admin/parents',
            icon: 'ri:parent-line',
          },
          {
            text: 'Transactions',
            path: '/admin/transactions',
            icon: 'mdi:account-payment-outline',
          },
          {
            text: 'Inquiries',
            path: '/admin/inquiries',
            icon: 'tabler:message-question',
          },
        ].map(({ text, icon, path }, index) => (
          <ListItem
            sx={{
              ':hover': {
                backgroundColor: '#10515c',
              },
            }}
            key={text}
            disablePadding
          >
            {
              <Box
                sx={{
                  width: '6px',
                  height: '60px',
                  backgroundColor:
                    location.pathname === path
                      ? (theme) => theme.palette.primary.main
                      : 'transparent',
                }}
              ></Box>
            }
            <ListItemButton
              onClick={() => {
                navigate(path);
              }}
              sx={{
                py: 2,
              }}
            >
              <ListItemIcon>
                <Icon color="white" icon={icon} fontSize={28} />
              </ListItemIcon>
              <Typography color={'white'} variant="subtitle1">
                {text}
              </Typography>
              {/* <ListItemText primary={text} /> */}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const auth = useAuth();
  const settings = [
    {
      label: 'Logout',
      onClick: () => {
        auth.removeAuth();
      },
    },
  ];
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          boxShadow: '0px 4px 30px 0px #0000000D',
          backgroundColor: (theme) => theme.palette.common.white,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              paddingTop: 20.5,
              paddingBottom: 20.5,
            },
          }}
        >
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sizes="48px"
                alt={'Admin'}
                src="/static/images/avatar/2.jpg"
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Stack p={2} gap={0.4}>
              <Typography variant="subtitle1">{'Admin Name'}</Typography>
              <Typography variant="body2">{'Admin'}</Typography>
            </Stack>
            <Divider />
            {settings.map((setting) => (
              <MenuItem
                key={setting.label}
                onClick={() => {
                  setting.onClick();
                  handleCloseUserMenu();
                }}
              >
                <Typography textAlign="center">{setting.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            'display': { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            'display': { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

SideBar.propTypes = {
  window: PropTypes.func,
};

export default SideBar;
