import React, { useCallback, useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import UploadDocuments from '../../../components/common/UploadDocuments';
import { HookAutoComplete } from '../../../components/hook-form-fields/HookAutoComplete';
import { RHFMultiCheckbox } from '../../../components/hook-form-fields/HookCheckBoxField';
import { HookTextField } from '../../../components/hook-form-fields/HookTextField';
import { defaultAgeGroups } from '../../../defaults';
import useGetUserData from '../../../hooks/useGetUserData';
import {
  useGetDesignationsQuery,
  useGetQualificationsQuery,
  useGetTherapiesQuery,
} from '../../../store/apis/configApi';
import {
  useOnboardTherapistMutation,
  useUpdateProfileMutation,
  useVerifyTherapistMutation,
} from '../../../store/apis/user';
import { showToast } from '../../../utils/commonFunctions';
import RejectionForm from '../../admin/therapist/RejectionForm';

const validationSchema = yup.object({
  designation: yup
    .array()
    .required('At least one value is required')
    .min(1, 'At least one value is required'),
  qualifications: yup
    .array()
    .required('At least one value is required')
    .min(1, 'At least one value is required'),
  therapies: yup
    .array()
    .required('At least one value is required')
    .min(1, 'At least one value is required'),
  expertise: yup
    .string()
    .trim()
    .required('Expertise is required')
    .max(100, 'Expertise should be max of 100 characters')
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9\s\W]+$/,
      'Expertise should have at least one character'
    ),
  yearsOfExperience: yup
    .number()
    .nullable()
    .typeError('Please enter valid Years of experience')
    .required('Years of experience is required')
    .min(1, 'Years of experience must be at least one')
    .max(99, 'Years of experience cannot exceed two digits')
    .positive('Years of experience must be a positive number')
    .integer('Years of experience must be an integer'),

  description: yup
    .string()
    .trim()
    .required('Description is required')
    .max(400, 'Description should be max of 400 characters')
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9\s\W]+$/,
      'Description should have at least one character'
    ),
  ageGroup: yup
    .array()
    .required('At least one value is required')
    .min(1, 'At least one value is required'),
});
const DomainDetailForm = ({
  currentStep,
  getStatus,
  therapistData,
  setCurrentStep,
  fromProfile = false,
  fromAdmin = false,
  onCancel,
  editMode: defaultEditMode,
}) => {
  const { data: designations, isLoading: isDesignationLoading } =
    useGetDesignationsQuery();

  const { data: therapies, isLoading: isTherapiesLoading } =
    useGetTherapiesQuery();
  const theme = useTheme();
  const [editMode, setEditMode] = useState(
    (!fromProfile && !fromAdmin) || defaultEditMode?.includes('professional')
  );
  // const status = getStatus?.();

  useEffect(() => {
    if (defaultEditMode) {
      setEditMode(defaultEditMode?.includes('professional'));
    }
  }, [defaultEditMode]);
  const { role } = useGetUserData();
  const [showRejectForm, setRejectForm] = useState(false);
  const [verify, { isLoading: verificationLoading }] =
    useVerifyTherapistMutation();

  const [updateProfile, { isLoading: profileUpdate }] =
    useUpdateProfileMutation();

  const [ageGroups, setAgeGroups] = useState([
    ...defaultAgeGroups,
    ...(therapistData?.ageGroup || [])
      .map((grp) => `${grp.lowest}-${grp.highest}`)
      .filter((grp) => !defaultAgeGroups.includes(grp)),
  ]);

  const [showAgeGroups, setShowAgeGroups] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [onboard, { isLoading }] = useOnboardTherapistMutation();
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      designation: therapistData?.info?.designation || [],
      qualifications: therapistData?.info?.qualifications || [],
      therapies: therapistData?.info?.therapies || [],
      expertise: therapistData?.info?.expertise || '',
      description: therapistData?.info?.description || '',
      yearsOfExperience: therapistData?.info?.yearsOfExperience || '',
      ageGroup: therapistData?.ageGroup?.map(
        (grp) => `${grp?.lowest}-${grp?.highest}`
      ),
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const selectedDesignation = watch('designation');

  const { data: validQualifications, isLoading: isQualificationsLoading } =
    useGetQualificationsQuery(
      {
        filter: selectedDesignation.map((des) =>
          typeof des === 'string' ? des : des?._id
        ),
      },
      { skip: !selectedDesignation?.length }
    );

  useEffect(() => {
    const selectedQualification = watch('qualifications');
    if (!selectedDesignation?.length) {
      setValue('qualifications', []);
      return;
    }
    const selectedQualificationClone = selectedQualification?.map((item) =>
      typeof item === 'string' ? item : item?._id
    );
    const allQualificationClone = validQualifications?.map((item) =>
      typeof item === 'string' ? item : item?._id
    );
    if (
      !isQualificationsLoading &&
      selectedQualificationClone.some(
        (item) => !allQualificationClone?.includes(item)
      )
    ) {
      const remainingQualifications = selectedQualificationClone.filter(
        (item) => allQualificationClone?.includes(item)
      );

      setValue(
        'qualifications',
        remainingQualifications.map((item) =>
          validQualifications.find((data) => data?._id === item)
        )
      );
    }
  }, [
    isQualificationsLoading,
    setValue,
    validQualifications,
    watch,
    selectedDesignation,
  ]);

  useEffect(() => {
    reset({
      designation: therapistData?.info?.designation || [],
      qualifications: therapistData?.info?.qualifications || [] || [],
      therapies: therapistData?.info?.therapies || [] || [],
      expertise: therapistData?.info?.expertise || '',
      description: therapistData?.info?.description || '',
      yearsOfExperience: therapistData?.info?.yearsOfExperience || '',
      ageGroup: therapistData?.ageGroup?.map(
        (grp) => `${grp?.lowest}-${grp?.highest}`
      ),
    });
  }, [
    designations,
    reset,
    therapistData?.ageGroup,
    therapistData?.info?.description,
    therapistData?.info?.designation,
    therapistData?.info?.expertise,
    therapistData?.info?.qualifications,
    therapistData?.info?.therapies,
    therapistData?.info?.yearsOfExperience,
  ]);
  const onSubmit = useCallback(
    async (data) => {
      if (!documents?.length) {
        showToast({
          type: 'error',
          message: 'Documents are required',
        });
        return;
      }
      if (!data.ageGroup?.length) {
        showToast({
          type: 'error',
          message: 'Min. of one Age group is required',
        });
        return;
      }
      data.designation = data?.designation?.map((des) => des?._id);
      data.qualifications = data?.qualifications?.map((des) => des?._id);
      data.therapies = data?.therapies?.map((des) => des?._id);
      if (data?.ageGroup?.length) {
        data.ageGroup = data?.ageGroup?.map((age) => {
          return typeof age === 'string'
            ? {
                lowest: age?.includes('-') ? age?.split('-')?.[0] : age,
                highest: age?.split('-')?.[1],
              }
            : age;
        });
      }
      let formData = new FormData();
      let res = null;
      const urls = [];
      documents.forEach((file) => {
        if (file?.url) {
          urls.push(file?.url);
        } else {
          formData.append('documents', file);
        }
      });

      formData.append('documents', JSON.stringify(urls));

      const info = {
        designation: data?.designation,
        qualifications: data?.qualifications,
        therapies: data?.therapies,
        expertise: data?.expertise,
        description: data?.description,
        yearsOfExperience: data?.yearsOfExperience,
      };
      formData.append('ageGroup', JSON.stringify(data?.ageGroup));
      if (fromProfile) {
        formData.append('step', 2);
        formData.append('info', JSON.stringify(info));

        res = await updateProfile({
          body: formData,
          id: therapistData?._id,
          role,
        });
      } else {
        Object.keys(info).forEach((key) => {
          formData.append(
            key,
            typeof info[key] === 'object'
              ? JSON.stringify(info[key])
              : info[key]
          );
        });
        formData.append('onboardingStep', 2);
        res = await onboard({
          body: formData,
          isUpdate: therapistData?.onboarding?.step > 2,
        });
      }
      if (res?.error) {
        showToast({
          type: 'error',
          message: res.error.data.message,
        });
        return;
      }
      if (fromProfile) {
        if (res?.data?.status === 'SUCCESS') {
          showToast({
            type: 'success',
            message: 'Professional Details updated successfully',
          });
        }
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    },
    [
      documents,
      fromProfile,
      onboard,
      role,
      setCurrentStep,
      therapistData?._id,
      therapistData?.onboarding?.step,
      updateProfile,
    ]
  );

  useEffect(() => {
    setDocuments(
      therapistData?.info?.documents?.map((pa) => {
        return { url: pa };
      })
    );
  }, [therapistData?.info?.documents]);

  const approveStatus = async (data) => {
    const res = await verify({
      body: {
        step: 2,
        status: 'APPROVED',
      },
      id: therapistData?._id,
    });

    if (res?.error) {
      showToast({
        type: 'error',
        message: res?.error?.data?.message,
      });
      return;
    }
    showToast({
      type: 'success',
      message: res?.data?.message,
    });
  };

  return (
    <>
      <form
        style={{ height: '100%' }}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <Stack gap={3} height={'100%'} justifyContent={'space-between'}>
          <Stack>
            {!fromProfile && !fromAdmin && (
              <Typography mb={3} color={'secondary'} align="left" variant="h4">
                Professional Details
              </Typography>
            )}
            <Stack
              mt={3}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={3}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <HookAutoComplete
                    sx={{ flex: 1 }}
                    isLoading={isDesignationLoading}
                    readOnly={!editMode}
                    required={true}
                    disabled={isLoading || profileUpdate}
                    name={'designation'}
                    label="Designation"
                    control={control}
                    options={designations}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <HookAutoComplete
                    sx={{ flex: 1 }}
                    isLoading={isQualificationsLoading}
                    disabled={
                      isLoading || profileUpdate || !selectedDesignation?.length
                    }
                    readOnly={!editMode}
                    required={true}
                    options={
                      !selectedDesignation?.length ? [] : validQualifications
                    }
                    name={'qualifications'}
                    label="Qualification"
                    control={control}
                  />
                </Grid>
              </Grid>

              <HookAutoComplete
                isLoading={isTherapiesLoading}
                readOnly={!editMode}
                required={true}
                disabled={isLoading || profileUpdate}
                options={therapies}
                name={'therapies'}
                label="Therapies"
                control={control}
              />
              <Stack
                width={'100%'}
                direction={{ xs: 'column', sm: 'row' }}
                gap={3}
              >
                <HookTextField
                  inputProps={{ readOnly: !editMode }}
                  required={true}
                  name={'yearsOfExperience'}
                  label="Years of Experience"
                  disabled={isLoading || profileUpdate}
                  control={control}
                  type={'number'}
                />
                <HookTextField
                  inputProps={{ readOnly: !editMode }}
                  required={true}
                  disabled={isLoading || profileUpdate}
                  name={'expertise'}
                  label="Expertise"
                  control={control}
                />
              </Stack>
              <HookTextField
                inputProps={{ readOnly: !editMode }}
                multiline
                disabled={isLoading || profileUpdate}
                rows={3}
                required={true}
                name={'description'}
                label="Description"
                control={control}
              />
              <Stack gap={1} width={'100%'}>
                <Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography variant="h6">
                      {fromAdmin
                        ? 'Age groups therapist can handle'
                        : 'Age groups you can handle'}
                    </Typography>
                    {editMode && (
                      <Button
                        onClick={() => {
                          setShowAgeGroups((prev) => !prev);
                        }}
                        disabled={isLoading || profileUpdate}
                        variant="text"
                        size="small"
                      >
                        <IconButton
                          disabled={isLoading || profileUpdate}
                          color="primary"
                        >
                          <Icon icon={'gg:add'} fontSize={24} />
                        </IconButton>
                        Add Custom
                      </Button>
                    )}
                  </Stack>
                  <RHFMultiCheckbox
                    readOnly={!editMode}
                    disabled={isLoading || profileUpdate}
                    name="ageGroup"
                    options={ageGroups}
                    isRow={true}
                    control={control}
                  />
                  <Typography variant="caption" color={'error'}>
                    {errors?.ageGroup?.message}
                  </Typography>
                </Stack>
                {showAgeGroups ? (
                  <Stack
                    gap={3}
                    mt={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <HookTextField
                      name="lowest"
                      label="Start age"
                      control={control}
                    />
                    <HookTextField
                      name="highest"
                      label="End age"
                      control={control}
                    />
                    <IconButton
                      color="secondary"
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <Icon
                        fontSize={24}
                        onClick={() => {
                          if (
                            !/^\d+$/.test(getValues('lowest')) ||
                            !/^\d+$/.test(getValues('highest'))
                          ) {
                            setError('ageGroup', {
                              type: 'pattern',
                              message: 'Kindly enter valid age',
                            });
                            return;
                          }

                          if (getValues('lowest') && getValues('highest')) {
                            if (
                              isNaN(parseInt(parseInt(getValues('lowest')))) ||
                              isNaN(parseInt(parseInt(getValues('highest'))))
                            ) {
                              setError('ageGroup', {
                                type: 'pattern',
                                message: 'Please enter a valid age',
                              });
                              return;
                            }
                            if (
                              parseInt(getValues('lowest')) > 14 ||
                              parseInt(getValues('highest')) > 14
                            ) {
                              setError('ageGroup', {
                                type: 'pattern',
                                message: 'Kindly add age less than 14',
                              });
                              return;
                            }
                            if (
                              parseInt(getValues('lowest')) >=
                              parseInt(getValues('highest'))
                            ) {
                              setError('ageGroup', {
                                type: 'pattern',
                                message: 'Please add valid range',
                              });
                              return;
                            }
                            const newValue = `${getValues('lowest')}-${getValues('highest')}`;
                            if (ageGroups.includes(newValue)) {
                              showToast({
                                type: 'error',
                                message: 'Age group already exists',
                              });
                              return;
                            }
                            setAgeGroups((prev) => {
                              (prev || []).push(newValue);
                              return prev;
                            });
                            clearErrors();
                            setValue('lowest', null);
                            setValue('ageGroup', [
                              ...(getValues()?.ageGroup || []),
                              newValue,
                            ]);
                            setValue('highest', null);
                            setShowAgeGroups(false);
                          }
                        }}
                        icon="material-symbols:add"
                      />
                    </IconButton>
                  </Stack>
                ) : null}
              </Stack>
              {console.log('editMode', editMode)}
              <UploadDocuments
                disabled={isLoading || profileUpdate}
                editMode={!!editMode}
                setDocuments={setDocuments}
                documents={documents}
                header={'Supporting Documents'}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection={'row'}
            justifyContent={fromProfile ? 'flex-end' : 'space-between'}
            alignItems={'center'}
            gap={3}
            width={'100%'}
          >
            {!fromProfile && !fromAdmin && (
              <ButtonWithLoading
                startIcon={
                  <Icon
                    style={{ color: theme.palette.text.primary }}
                    icon={'material-symbols:arrow-back'}
                    fontSize={24}
                  />
                }
                fullWidth={false}
                onClick={() => {
                  setCurrentStep((prev) => prev - 1);
                }}
                variant="text"
                disabled={isLoading}
                title={'Back'}
              />
            )}

            <Stack alignItems={'flex-end'} width={'100%'}>
              {fromAdmin &&
              (!therapistData?.ranking?.isAdminVerified ||
                therapistData?.status === 'UNDER_REVERIFICATION') &&
              !['NOT_STARTED', 'IN_PROGRESS'].includes(
                therapistData?.onboarding?.status
              ) &&
              therapistData?.status !== 'DELETED' ? (
                !showRejectForm ? (
                  <Stack flexDirection={'row'} gap={2}>
                    <ButtonWithLoading
                      textColor={'error.main'}
                      variant={'text'}
                      customStyle={{ px: 4, mt: 3 }}
                      fullWidth={false}
                      isLoading={isLoading || profileUpdate}
                      title={'Reject'}
                      onClick={() => {
                        setRejectForm(true);
                      }}
                    />
                    <ButtonWithLoading
                      type={'button'}
                      onClick={approveStatus}
                      customStyle={{ px: 4, mt: 3 }}
                      fullWidth={false}
                      isLoading={
                        verificationLoading || isLoading || profileUpdate
                      }
                      title={'Approve'}
                    />
                  </Stack>
                ) : null
              ) : (
                editMode && (
                  <Stack flexDirection={'row'} gap={2}>
                    {fromProfile && (
                      <ButtonWithLoading
                        variant="text"
                        onClick={() => {
                          reset({
                            designation: therapistData?.info?.designation || [],
                            qualifications:
                              therapistData?.info?.qualifications || [],
                            expertise: therapistData?.info?.expertise || [],
                            therapies: therapistData?.info?.therapies || [],
                            description: therapistData?.info?.description || '',
                            yearsOfExperience:
                              therapistData?.info?.yearsOfExperience || '',
                            ageGroup: therapistData?.ageGroup.map(
                              (grp) => `${grp?.lowest}-${grp?.highest}`
                            ),
                          });

                          onCancel();
                          // setEditMode(false);
                        }}
                        customStyle={{ px: 4, mt: 3 }}
                        fullWidth={false}
                        disabled={isLoading || profileUpdate}
                        title={'Cancel'}
                      />
                    )}
                    <ButtonWithLoading
                      type={'submit'}
                      customStyle={{ px: 4, mt: 3 }}
                      fullWidth={false}
                      isLoading={isLoading || profileUpdate}
                      title={fromProfile ? 'Save' : 'Next'}
                    />
                  </Stack>
                )
              )}
            </Stack>
          </Stack>
        </Stack>
      </form>
      {showRejectForm && (
        <RejectionForm
          step={2}
          therapistId={therapistData?._id}
          setRejectForm={setRejectForm}
        />
      )}
    </>
  );
};

DomainDetailForm.propTypes = {
  currentStep: PropTypes.any,
  getStatus: PropTypes.func,
  editMode: PropTypes.any,
  fromAdmin: PropTypes.bool,
  fromProfile: PropTypes.bool,
  onCancel: PropTypes.func,
  setCurrentStep: PropTypes.func,
  therapistData: PropTypes.shape({
    _id: PropTypes.any,
    ageGroup: PropTypes.array,
    info: PropTypes.shape({
      description: PropTypes.string,
      designation: PropTypes.array,
      documents: PropTypes.array,
      expertise: PropTypes.string,
      qualifications: PropTypes.array,
      therapies: PropTypes.array,
      yearsOfExperience: PropTypes.any,
    }),
    onboarding: PropTypes.shape({
      step: PropTypes.number,
      status: PropTypes.string,
    }),
    reVerification: PropTypes.shape({
      adminStatus: PropTypes.string,
    }),
    ranking: PropTypes.shape({
      isAdminVerified: PropTypes.bool,
    }),
    status: PropTypes.string,
  }),
};

export default DomainDetailForm;
