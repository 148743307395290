import React, { useState } from 'react';

import { Icon } from '@iconify/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const AccordionDetailWrapper = ({
  title,
  children,
  renderAction,
  getStatus,
  expanded = false,
  role,
}) => {
  const [open, setOpen] = useState(expanded);
  const status = getStatus?.();

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      elevation={0}
      sx={{
        'px': 1,
        'transition': 'all 500ms linear',
        '&:before': { height: '0px' },
      }}
    >
      <AccordionSummary
        sx={{
          'borderRadius': '12px !important',
          'py':
            !(status === undefined || status === 'UNDER_REVERIFICATION') &&
            role === 'admin'
              ? 0.3
              : 1.4,
          'backgroundColor': (theme) =>
            role === 'admin'
              ? status === 'APPROVED'
                ? theme.palette.primary.contrastText
                : status === 'REJECTED' || status === 'REVERIFICATION_FAILED'
                  ? 'error.light'
                  : !open
                    ? 'background.accordion'
                    : 'white'
              : status === 'REJECTED'
                ? 'error.light'
                : !open
                  ? 'background.accordion'
                  : 'white',
          '& .MuiAccordionSummary-content.Mui-expanded': {
            my: '0px !important',
          },
        }}
        expandIcon={<Icon fontSize={24} icon={'ic:baseline-expand-more'} />}
      >
        <Stack
          width={'100%'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={2}
        >
          {role === 'admin' &&
            ![undefined, 'PENDING', 'UNDER_REVERIFICATION'].includes(
              status
            ) && (
              <Stack
                sx={{
                  borderWidth: 1,
                  borderStyle: 'dashed',
                  borderColor: (theme) =>
                    status ? 'primary.main' : 'error.main',
                  p: '3.2px',
                  borderRadius: '50%',
                }}
              >
                <Stack
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{
                    p: '7px',
                    borderRadius: '50%',
                    backgroundColor:
                      status === 'APPROVED' ? 'primary.main' : 'error.main',
                  }}
                >
                  <Icon
                    color="white"
                    icon={
                      status === 'APPROVED'
                        ? 'zondicons:checkmark'
                        : 'gridicons:cross'
                    }
                    fontSize={20}
                  />
                </Stack>
              </Stack>
            )}
          <Stack
            direction={'row'}
            alignItems={'center'}
            width={'100%'}
            justifyContent={'space-between'}
            mr={2}
          >
            <Typography
              sx={{ transition: 'all 500ms linear' }}
              lineHeight={1}
              variant={open ? 'h5' : 'h6'}
              color={
                [
                  undefined,
                  'PENDING',
                  'APPROVED',
                  'UNDER_REVERIFICATION',
                ].includes(status)
                  ? 'text.primary'
                  : 'error.main'
              }
            >
              {title}
            </Typography>
            {open && renderAction}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: { xs: 2, sm: 3 } }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionDetailWrapper.propTypes = {
  children: PropTypes.any,
  getStatus: PropTypes.func,
  renderAction: PropTypes.any,
  status: PropTypes.any,
  title: PropTypes.any,
  expanded: PropTypes.bool,
  role: PropTypes.string,
};

export default AccordionDetailWrapper;
